import React, { useEffect, useState, Fragment, memo } from 'react'
import { Navbar, Container, Nav, Dropdown, Modal, Form, Button, Toast, ToastContainer } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import CustomToggle from '../../../dropdowns'
import { getFirestore, doc, getDoc, Timestamp, updateDoc, collection, getDocs, query, where, deleteDoc } from "firebase/firestore";
import './header.css';

import shapes1 from '../../../../assets/images/shapes/01.png'
import shapes2 from '../../../../assets/images/shapes/02.png'
import shapes3 from '../../../../assets/images/shapes/03.png'
import shapes4 from '../../../../assets/images/shapes/04.png'
import shapes5 from '../../../../assets/images/shapes/05.png'
import avatars1 from '../../../../assets/images/avatars/01.png'

// logo
import Logo from '../../components/logo'

// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'
const db = getFirestore();

const Header = memo((props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [editUser, setEditUser] = useState(null); // Estado para almacenar los datos del usuario en edición
    const [users, setUsers] = useState([]);

    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const headerNavbar = useSelector(SettingSelector.header_navbar)
    const [user, setUser] = useState(null);  // Estado para guardar la información del usuario
    const navigate = useNavigate();  // Para redireccionar después de cerrar sesión
    const userId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado
    const [userRole, setUserRole] = useState(null);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword, setShowPassword] = useState(false);
    // Función para eliminar el ID de la sesión
    const handleDeleteUserId = () => {
        localStorage.removeItem('userId'); // Eliminar el ID del usuario de localStorage     
    };
    const fetchUserData = async () => {
        if (!userId) {
            return;
        }

        try {
            // Referencia al documento del usuario en Firestore
            const userDocRef = doc(db, "users", userId);

            // Obtener el documento del usuario
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // Combinar el firstName y lastName
                const userData = userDoc.data();

                const fullName = `${userData.firstName} ${userData.lastName}`;

                // Actualizar el estado del usuario con el nombre completo
                setUser({ fullName, email: userData.email });

            } else {
                console.warn("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    useEffect(() => {
        fetchUserData();
    }, []);
    const minisidebar = () => {
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini');
    };
    const handleEdit = async () => {
        const userId = localStorage.getItem('userId');

        if (!userId) {
            console.warn("No hay sesión de usuario activa");
            return;
        }

        try {
            const userDocRef = doc(db, "users", userId);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                const fullName = `${userData.firstName} ${userData.lastName}`;

                setEditUser({
                    id: userId,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    password: userData.password,
                    phone: userData.phone,
                    role: userData.role,
                    createdAt: userData.createdAt ? userData.createdAt.toDate().toISOString().slice(0, 16) : '' // Formato datetime-local
                });

                handleShow();
            } else {
                console.warn("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    const handleUpdateUser = async () => {
        if (!editUser.id) {
            console.error("ID de usuario no definido.");
            return;
        }

        try {
            const userDocRef = doc(db, "users", editUser.id);
            const timestamp = editUser.createdAt ? Timestamp.fromDate(new Date(editUser.createdAt)) : Timestamp.now(); // Convertir a Timestamp

            await updateDoc(userDocRef, {
                firstName: editUser.firstName,
                lastName: editUser.lastName,
                email: editUser.email,
                password: editUser.password,
                phone: editUser.phone,
                role: editUser.role,
                createdAt: timestamp
            });

            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === editUser.id ? { ...user, ...editUser, createdAt: timestamp } : user
                )
            );
            fetchUserData();
            setToastMessage('Usuario actualizado correctamente');
            setToastVariant('success');
            setShowToast(true);
            handleClose();
        } catch (error) {
            console.error("Error actualizando usuario:", error);
            setToastMessage('Error actualizando usuario');
            setToastVariant('danger');
            setShowToast(true);
        }
    };
    useEffect(() => {
        const fetchUserRole = async () => {
            if (userId) {
                const userRef = doc(db, "users", userId);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserRole(userData.role);  // Guardar el rol del usuario
                } else {
                    setToastMessage("No se encontró el documento del usuario.");
                    setToastVariant('danger');
                    setTimeout(() => {
                        navigate("/");
                    }, 1600);
                }
            } else {
                setToastMessage("No se encontró el ID del usuario.");
                setToastVariant('danger');
                setTimeout(() => {
                    navigate("/");
                }, 1600);

            }
        };

        fetchUserRole();
    }, [navigate]);


    const [notificaciones, setNotificaciones] = useState([]);
    const fetchNotifications = async () => {
        try {
            if (!userId) {
                setError('No se pudo obtener el ID del vendedor.');
                setLoading(false);
                return;
            }
    
            const userDoc = doc(db, 'users', userId);
            const userSnapshot = await getDoc(userDoc);
            const userRole = userSnapshot.exists() ? userSnapshot.data().role : null;
    
            if (!userRole) {
                setToastMessage('No se pudo obtener el rol del usuario.');
                setToastVariant('danger');
                setShowToast(true);
                setLoading(false);
                return;
            }
    
            // Inicializar las consultas
            let qPago, qRevision;
            
            if (userRole === 'admin') {
                qPago = query(
                    collection(db, 'notifications'),
                    where('type', '==', 'Notificación de pago') // Obtener solo notificaciones de pago
                );
                qRevision = query(
                    collection(db, 'notifications'),
                    where('type', '==', 'Revision') // Obtener solo notificaciones de revisión
                );
            } else if (userRole === 'user') {
                qPago = query(
                    collection(db, 'notifications'),
                    where('id_user', '==', userId),
                    where('type', '==', 'Notificación de pago')
                );
                qRevision = query(
                    collection(db, 'notifications'),
                    where('id_user', '==', userId),
                    where('type', '==', 'Revision')
                );
            }
    
            // Obtener las notificaciones de pago
            const [querySnapshotPago, querySnapshotRevision] = await Promise.all([
                getDocs(qPago),
                getDocs(qRevision),
            ]);
    
            const pagoNotifications = querySnapshotPago.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            const revisionNotifications = querySnapshotRevision.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            // Agrupar notificaciones de pago por contrato (id_contract) y tomar solo una por contrato
            const uniquePagoNotificationsByContract = Object.values(
                pagoNotifications.reduce((acc, notification) => {
                    if (!acc[notification.id_contract]) {
                        acc[notification.id_contract] = notification; // Almacenar solo la primera notificación por contrato
                    }
                    return acc;
                }, {})
            );
    
            // Combinar notificaciones de tipo "Notificación de pago" (únicas por contrato) y "Revision"
            const allNotifications = [...uniquePagoNotificationsByContract, ...revisionNotifications];
        
            setNotificaciones(allNotifications);
        } catch (err) {
            console.error('Error al obtener notificaciones: ', err);
            setError('Error al obtener notificaciones.');
        } finally {
            setLoading(false);
        }
    };
    
    
    useEffect(() => {
        fetchNotifications();
    }, [userId]);

    // Si existen notificaciones, cambia la apariencia del icono
    const hayNotificaciones = notificaciones.length > 0;


    const [showEditModal, setShowEditModal] = useState(false);
    const [currentNotification, setCurrentNotification] = useState(null); // Datos actuales de la notificación a editar
    const [editedNotification, setEditedNotification] = useState({
        corrections: '',

    }); // Datos editados

    // Función para editar una notificación
    const editarNotificacion = async (notificacionId, nuevosDatos) => {
        try {
            const notificacionRef = doc(db, 'notifications', notificacionId);
            await updateDoc(notificacionRef, nuevosDatos);
            setToastMessage('Notificación editada correctamente.');
            setToastVariant('success');
            setShowToast(true);

            // Refrescar las notificaciones
            fetchNotifications();
        } catch (err) {
            setToastMessage('Error al editar la notificación.');
            setToastVariant('danger');
            setShowToast(true);
        }
    };

    const openEditModal = (notificacion) => {
        setCurrentNotification(notificacion);
        setEditedNotification({
            corrections: notificacion.corrections,

        });
        setShowEditModal(true);
    };

    const handleEditSubmit = async () => {
        if (currentNotification && editedNotification.corrections) {
            await editarNotificacion(currentNotification.id, editedNotification); // Usar la función de editar notificación
            setShowEditModal(false); // Cerrar el modal tras la edición
        } else {
            setToastMessage('Debe completar todos los campos.');
            setToastVariant('danger');
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [notificationToDelete, setNotificationToDelete] = useState(null);
    const openDeleteModal = (notificacion) => {
        setNotificationToDelete(notificacion); // Guardamos toda la notificación para mostrar detalles
        setShowDeleteModal(true);
    };


    const eliminarNotificacion = async (notificationId) => {
        try {
            // Verifica que notificationId es una cadena válida
            if (typeof notificationId !== 'string') {
                throw new Error('El ID de la notificación no es válido.');
            }

            const notificationDoc = doc(db, 'notifications', notificationId);
            await deleteDoc(notificationDoc);

            setToastMessage('Notificación eliminada exitosamente.');
            setToastVariant('success');
            setShowToast(true);
            fetchNotifications();
            setShowDeleteModal(false);
        } catch (error) {
            setToastMessage('Error al eliminar la notificación.');
            setToastVariant('danger');
            setShowToast(true);
        }
    };

    if (userRole === null) {
        return <div>Cargando...</div>;  // Mensaje de carga mientras se obtiene el rol del usuario
    }
    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Datos Personales</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {userRole === 'admin' ? (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="firstName">Nombres</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="firstName"
                                    placeholder="Ingresar Nombres Completos"
                                    value={editUser ? editUser.firstName : ''}
                                    onChange={(e) => setEditUser({ ...editUser, firstName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="lastName">Apellidos</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="lastName"
                                    placeholder="Ingresar Apellidos Completos"
                                    value={editUser ? editUser.lastName : ''}
                                    onChange={(e) => setEditUser({ ...editUser, lastName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Correo Electrónico</Form.Label>
                                <Form.Control
                                    type="email"
                                    id="email"
                                    placeholder="Ingresar Un Correo Electrónico"
                                    value={editUser ? editUser.email : ''}
                                    onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="password">Contraseña</Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"} // Alterna entre 'text' y 'password'
                                        id="password"
                                        placeholder="Ingresar Contraseña"
                                        value={editUser ? editUser.password : ''}
                                        onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
                                    />

                                    <Button
                                        variant="secondary"
                                        onClick={togglePasswordVisibility}
                                        className="input-group-text"
                                    >
                                        {showPassword ? "Ocultar" : "Mostrar"}
                                    </Button>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">No. Celular</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="phone"
                                    placeholder="Ingresar Un Numero de Celular"
                                    value={editUser ? editUser.phone : ''}
                                    onChange={(e) => setEditUser({ ...editUser, phone: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="role">Rol</Form.Label>
                                <Form.Select
                                    type="text"
                                    id="role"
                                    placeholder="Ingresar Rol"
                                    value={editUser ? editUser.role : ''}
                                    onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
                                >
                                    <option disabled value="">Seleccionar Rol</option>
                                    <option value="admin">Administrador</option>
                                    <option value="user">Vendedor</option>

                                </Form.Select>

                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="createdAt">Fecha de Creación</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    id="createdAt"
                                    placeholder=""
                                    value={editUser ? editUser.createdAt : ''}
                                    onChange={(e) => setEditUser({ ...editUser, createdAt: e.target.value })}
                                />
                            </Form.Group>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={handleUpdateUser}>
                                    Actualizar
                                </Button>
                            </Modal.Footer>


                        </>
                    ) : userRole === 'user' ? (

                        <>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="firstName">Nombres</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="firstName"
                                    placeholder="Ingresar Nombres Completos"
                                    value={editUser ? editUser.firstName : ''}
                                    onChange={(e) => setEditUser({ ...editUser, firstName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="lastName">Apellidos</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="lastName"
                                    placeholder="Ingresar Apellidos Completos"
                                    value={editUser ? editUser.lastName : ''}
                                    onChange={(e) => setEditUser({ ...editUser, lastName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Correo Electrónico</Form.Label>
                                <Form.Control
                                    type="email"
                                    id="email"
                                    placeholder="Ingresar Un Correo Electrónico"
                                    value={editUser ? editUser.email : ''}
                                    onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="password">Contraseña</Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"} // Alterna entre 'text' y 'password'
                                        id="password"
                                        placeholder="Ingresar Contraseña"
                                        value={editUser ? editUser.password : ''}
                                        onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
                                    />
                                    <Button
                                        variant="secondary"
                                        onClick={togglePasswordVisibility}
                                        className="input-group-text"
                                    >
                                        {showPassword ? "Ocultar" : "Mostrar"}
                                    </Button>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">No. Celular</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="phone"
                                    placeholder="Ingresar Un Numero de Celular"
                                    value={editUser ? editUser.phone : ''}
                                    onChange={(e) => setEditUser({ ...editUser, phone: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="role">Rol</Form.Label>
                                <Form.Control
                                    readOnly
                                    type="text"
                                    id="role"
                                    placeholder="Ingresar Rol"
                                    value={editUser ? editUser.role : ''}
                                    onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="createdAt">Fecha de Creación</Form.Label>
                                <Form.Control
                                    readOnly
                                    type="datetime-local"
                                    id="createdAt"
                                    placeholder=""
                                    value={editUser ? editUser.createdAt : ''}
                                    onChange={(e) => setEditUser({ ...editUser, createdAt: e.target.value })}
                                />
                            </Form.Group>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={handleUpdateUser}>
                                    Actualizar
                                </Button>
                            </Modal.Footer>


                        </>
                    ) : (
                        <div>No tienes acceso a esta página</div>
                    )}

                </Modal.Body>
            </Modal>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Notificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNotificationTitle">
                            <Form.Label>Correcciones</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedNotification.corrections}
                                onChange={(e) =>
                                    setEditedNotification({ ...editedNotification, corrections: e.target.value })
                                }
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleEditSubmit}>
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {notificationToDelete ? (
                        <>
                            <p>¿Estás seguro de que deseas eliminar esta notificación?</p>
                            <p><strong>Información:</strong> {notificationToDelete.corrections}</p>
                            <p><strong>Cliente:</strong> {notificationToDelete.client}</p>
                            <p><strong>Contrato:</strong> {notificationToDelete.id_contract}</p>

                            <p>
                                <strong>Fecha:</strong>{' '}
                                {notificationToDelete.timestamp
                                    ? new Date(notificationToDelete.timestamp.toDate()).toLocaleString()  // Convierte correctamente el timestamp
                                    : 'Fecha no disponible'}
                            </p>

                        </>
                    ) : (
                        <p>Cargando datos de la notificación...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => eliminarNotificacion(notificationToDelete.id)}>
                        Confirmar Eliminación
                    </Button>

                </Modal.Footer>
            </Modal>




            <Navbar expand="lg" variant="light" className={`nav iq-navbar ${headerNavbar} ${navbarHide.join(" ")}`}>
                <Container fluid className="navbar-inner">
                    <Link to="#" className="navbar-brand">
                        <Logo color={true} />
                        <h4 className="logo-title">Stroit Corp</h4>
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                        <i className="icon">
                            <svg width="20px" height="20px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                            </svg>
                        </i>
                    </div>

                    <Navbar.Toggle aria-controls="navbarSupportedContent">
                        <span className="navbar-toggler-icon">
                            <span className="mt-2 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav as="ul" className="mb-2 ms-auto navbar-list mb-lg-0 align-items-center">

                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} href="#" variant=" nav-link" id="notification-drop" data-bs-toggle="dropdown">
                                    <svg
                                        width="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={hayNotificaciones ? 'notificaciones-activas' : ''}
                                    >
                                        <path
                                            d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            opacity="0.4"
                                            d="M14.0088 19.2283C13.5088 19.1215 10.4627 19.1215 9.96275 19.2283C9.53539 19.327 9.07324 19.5566 9.07324 20.0602C9.09809 20.5406 9.37935 20.9646 9.76895 21.2335L9.76795 21.2345C10.2718 21.6273 10.8632 21.877 11.4824 21.9667C11.8123 22.012 12.1482 22.01 12.4901 21.9667C13.1083 21.877 13.6997 21.6273 14.2036 21.2345L14.2026 21.2335C14.5922 20.9646 14.8734 20.5406 14.8983 20.0602C14.8983 19.5566 14.4361 19.327 14.0088 19.2283Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                    {hayNotificaciones && <span className="bg-danger dots"></span>}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '500px', maxHeight: '600px', overflowY: 'auto' }}
                                    className="p-0 sub-drop dropdown-menu-end" aria-labelledby="notification-drop">
                                    <div className="m-0 shadow-none card">
                                        <div className="py-3 card-header d-flex justify-content-between bg-primary">
                                            <div className="header-title">
                                                <h5 className="mb-0 text-white">Notificaciones</h5>
                                            </div>
                                            <button onClick={fetchNotifications} className="btn btn-info">Actualizar</button>
                                        </div>

                                        <div className="p-0 card-body">
                                            {notificaciones.map((notificacion, index) => (
                                                <Link to="#" className="iq-sub-card" key={notificacion.id}>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            className="p-1 avatar-40 rounded-pill bg-soft-primary"
                                                            src={
                                                                index % 5 === 0 ? shapes1 : index % 5 === 1
                                                                    ? shapes2 : index % 5 === 2
                                                                        ? shapes3 : index % 5 === 3
                                                                            ? shapes4 : shapes5
                                                            }
                                                            alt="shape"
                                                        />

                                                        <div className="ms-3 w-100">
                                                            <h6 className="mb-0 ">Información: {notificacion.corrections}</h6>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0">Cliente: {notificacion.client}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0">Contrato N°: {notificacion.id_contract}</p>
                                                            </div>
                                                            <p className="mb-0">
                                                                Fecha: {notificacion.timestamp ? new Date(notificacion.timestamp.seconds * 1000).toLocaleString() : 'Fecha no disponible'}
                                                            </p>

                                                        </div>
                                                        {userRole === 'admin' ? (
                                                            <>
                                                                <Link
                                                                    onClick={() => openEditModal(notificacion)}
                                                                    className="btn btn-sm btn-icon btn-warning m-1"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Edit"
                                                                    data-original-title="Edit"
                                                                    to="#"
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>

                                                                <Link
                                                                    className="btn btn-sm btn-icon btn-danger"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Eliminar"
                                                                    to="#"
                                                                    onClick={() => openDeleteModal(notificacion)}
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg
                                                                            width="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M20.708 6.23975H3.75"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                            </>
                                                        ) : userRole === 'user' ? (

                                                            <>
                                                                <Link
                                                                    className="btn btn-sm btn-icon btn-danger"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Eliminar"
                                                                    to="#"
                                                                    onClick={() => openDeleteModal(notificacion)}
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg
                                                                            width="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M20.708 6.23975H3.75"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                            </>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>






                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                    <img src={avatars1} alt="User-Profile" className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded" />

                                    <div className="caption ms-3 d-none d-md-block ">
                                        <h6 className="mb-0 caption-title">{user ? user.fullName : "Usuario"}</h6>
                                        <p className="mb-0 caption-sub-title">{user ? user.email : "Desarrollador"}</p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <Dropdown.Item onClick={handleEdit}>Perfil</Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={handleDeleteUserId} href="/">Cerrar Sesión</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant === 'success' ? 'success' : 'danger'}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Fragment>
    )
})

export default Header
