import React from 'react'
import Horizontal from '../layouts/dashboard/horizontal'



export const IndexRouters = [
    {
        path: 'horizontal',
        element: <Horizontal />
    }
]
