import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button, Toast, ToastContainer } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'

import { getFirestore, doc, setDoc, collection, getDocs, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../firebase/firebase_settings';

// Inicializa Firebase
initializeApp(firebaseConfig);
const db = getFirestore();

// David 
const UserList = () => {
   const [toastMessage, setToastMessage] = useState('');
   const [toastVariant, setToastVariant] = useState('');
   const [showToast, setShowToast] = useState(false);

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const [showPassword, setShowPassword] = useState(false);

   const [show, setShow] = useState(false);
   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);

   const [show2, setShow2] = useState(false);
   const handleShow2 = () => setShow2(true);
   const handleClose2 = () => setShow2(false);

   const [showModal, setShowModal] = useState(false);
   const [selectedUser, setSelectedUser] = useState(null);
   const [searchTerm, setSearchTerm] = useState('');

   const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      role: '',
      createdAt: ''
   });

   const [error, setError] = useState('');

   const [users, setUsers] = useState([]);
   const [editUserId, setEditUserId] = useState('');

   // Manejar el cambio de los inputs del formulario
   const handleChange = (e) => {
      setFormData({ ...formData, [e.target.id]: e.target.value });
   };

   const handleSignUp = async () => {
      const { firstName, lastName, email, phone, password, confirmPassword, createdAt } = formData;
      setError('');

      if (password !== confirmPassword) {
         setError('Las contraseñas no coinciden');
         return;
      }

      try {
         // Generar un ID único para el nuevo usuario
         const newUserId = generateRandomId();
         const timestamp = createdAt ? Timestamp.fromDate(new Date(createdAt)) : Timestamp.now();

         // Guardar los datos del usuario en Firestore
         await setDoc(doc(db, "users", newUserId), {
            id: newUserId,
            firstName,
            lastName,
            email,
            phone,
            password,
            createdAt: timestamp,
            role: 'user'
         });

         console.log('Usuario registrado correctamente');
         setToastMessage('Usuario registrado correctamente');
         setToastVariant('success');
         setShowToast(true);
         handleClose();
         fetchUsers();
      } catch (error) {
         console.error('Error al crear la cuenta:', error);
         setError('Error al crear la cuenta: ' + error.message);

         setToastMessage('Error al crear la cuenta');
         setToastVariant('danger');
         setShowToast(true);
      }
   };

   // Función para generar un ID único para el usuario
   const generateRandomId = () => {
      return 'user_' + Math.random().toString(36).substr(2, 9);
   };

   const fetchUsers = async () => {
      try {
         const usersCollection = collection(db, "users");
         const usersSnapshot = await getDocs(usersCollection);
         const usersList = usersSnapshot.docs.map(doc => doc.data());
         setUsers(usersList);
      } catch (error) {
         console.error('Error al obtener usuarios:', error);
      }
   };

   useEffect(() => {
      fetchUsers();
   }, []);

   const handleEdit = (user) => {
      console.log('Usuario a editar:', user);
      setFormData({
         id: user.id,
         firstName: user.firstName,
         lastName: user.lastName,
         email: user.email,
         password: user.password,
         phone: user.phone,
         role: user.role,
         createdAt: user.createdAt ? user.createdAt.toDate().toISOString().slice(0, 16) : '' // Convertir a formato datetime-local

      });
      setEditUserId(user.id);
      handleShow2();
   };

   const handleUpdateUser = async () => {
      if (!editUserId) {
         console.error("ID de usuario no definido.");
         return;
      }

      try {
         const userDocRef = doc(db, "users", editUserId);
         const timestamp = formData.createdAt ? Timestamp.fromDate(new Date(formData.createdAt)) : Timestamp.now(); // Convertir a Timestamp

         await updateDoc(userDocRef, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password,
            phone: formData.phone,
            role: formData.role,
            createdAt: timestamp
         });

         setUsers((prevUsers) =>
            prevUsers.map((user) =>
               user.id === editUserId ? { ...user, ...formData, createdAt: timestamp } : user
            )
         );

         console.log('Usuario actualizado correctamente');
         setToastMessage('Usuario actualizado correctamente');
         setToastVariant('success');
         setShowToast(true);
         handleClose2();
         fetchUsers();
      } catch (error) {
         console.error("Error actualizando usuario:", error);

         setToastMessage('Error actualizando usuario');
         setToastVariant('danger');
         setShowToast(true);
      }
   };



   const handleDeleteUser = async (userId) => {
      try {
         await deleteDoc(doc(db, "users", userId));
         setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
         console.log('Usuario eliminado correctamente');
         setToastMessage('Usuario eliminado correctamente');
         setToastVariant('success');
         setShowToast(true);
         fetchUsers();
      } catch (error) {
         console.error('Error al eliminar el usuario:', error);
         setToastMessage('Error al eliminar el usuario');
         setToastVariant('danger');
         setShowToast(true);
      }
   };
   // Función para abrir el modal con los datos del usuario seleccionado
   const openModal = (user) => {
      setSelectedUser(user); // Almacena los datos del usuario
      setShowModal(true); // Muestra el modal
   };

   // Función para confirmar la eliminación
   const confirmDelete = () => {
      if (selectedUser) {
         handleDeleteUser(selectedUser.id); // Llama a la función de eliminación
         setShowModal(false); // Cierra el modal
      }
   };

   const filteredUsers = users.filter(user =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
   );


   return (
      <>
         <div>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Vendedores</h4>
                        </div>
                        <button class="btn btn-primary" onClick={handleShow}>
                           Nuevo Vendedor
                        </button>

                     </Card.Header>
                     <Card.Body className="px-0">

                        <Modal show={show} onHide={handleClose}>
                           <Modal.Header closeButton>
                              <Modal.Title>Agregar Nuevo Vendedor</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="firstName">Nombres</Form.Label>
                                 <Form.Control required type="text" id="firstName" placeholder="Ingresar Nombres Completos" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="lastName">Apellidos</Form.Label>
                                 <Form.Control required type="text" id="lastName" placeholder="Ingresar Apellidos Completos" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="email">Correo Electrónico</Form.Label>
                                 <Form.Control required type="email" id="email" placeholder="Ingresar Un Correo Electrónico" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="phone">No. Celular</Form.Label>
                                 <Form.Control required type="number" id="phone" placeholder="Ingresar Un Numero de Celular" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="password">Contraseña</Form.Label>
                                 <Form.Control required type="password" id="password" value={formData.password} placeholder="Ingresar Una Contraseña" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="confirmPassword">Repita la contraseña</Form.Label>
                                 <Form.Control required type="password" id="confirmPassword" value={formData.confirmPassword} placeholder="Confirmar la contraseña" onChange={handleChange} />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label htmlFor="createdAt">Fecha de Creacion</Form.Label>
                                 <Form.Control required name="createdAt" type="datetime-local" id="createdAt" value={formData.createdAt}
                                    placeholder=""
                                    onChange={(e) => setFormData({ ...formData, createdAt: e.target.value })}

                                 />
                              </Form.Group>

                              <Modal.Footer>
                                 <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                 </Button>
                                 <Button variant="primary" onClick={handleSignUp}>
                                    Agregar Usuario
                                 </Button>
                              </Modal.Footer>

                           </Modal.Body>
                        </Modal>

                        <Modal show={show2} onHide={handleClose2}>
                           <Modal.Header closeButton>
                              <Modal.Title>Editar Usuario</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                              <form>
                                 <div className="form-group">
                                    <label>Nombre</label>
                                    <input
                                       type="text"
                                       id="firstName"
                                       value={formData.firstName}
                                       onChange={handleChange}
                                       className="form-control"
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label>Apellido</label>
                                    <input
                                       type="text"
                                       id="lastName"
                                       value={formData.lastName}
                                       onChange={handleChange}
                                       className="form-control"
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label>Email</label>
                                    <input
                                       type="email"
                                       id="email"
                                       value={formData.email}
                                       onChange={handleChange}
                                       className="form-control"
                                    />
                                 </div>

                                 <div className="form-group">
                                    <label>Contraseña</label>
                                    <div className="input-group">
                                       <input
                                          type={showPassword ? "text" : "password"} // Alterna entre 'text' y 'password'
                                          id="password"
                                          value={formData.password}
                                          onChange={handleChange}
                                          className="form-control"
                                       />
                                       <button
                                          type="button"
                                          className="btn btn-secondary"
                                          onClick={togglePasswordVisibility}
                                       >
                                          {showPassword ? "Ocultar" : "Mostrar"} {/* Cambia el texto del botón */}
                                       </button>
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <label>Teléfono</label>
                                    <input
                                       type="text"
                                       id="phone"
                                       value={formData.phone}
                                       onChange={handleChange}
                                       className="form-control"
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor="role">Rol</label>
                                    <select
                                       id="role"
                                       value={formData.role}
                                       onChange={handleChange}
                                       className="form-control"
                                    >
                                       <option value="" disabled>Seleccionar</option>
                                       <option value="user">Vendedor</option>
                                       <option value="admin">Administrador</option>
                                    </select>
                                 </div>

                                 <div className="form-group">
                                    <label>Fecha</label>
                                    <input
                                       type="datetime-local"
                                       id="createdAt"
                                       value={formData.createdAt}
                                       onChange={handleChange}
                                       className="form-control"
                                    />
                                 </div>
                              </form>
                           </Modal.Body>
                           <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose2}>
                                 Cancelar
                              </Button>
                              <Button variant="primary" onClick={handleUpdateUser}>
                                 Actualizar Usuario
                              </Button>
                           </Modal.Footer>
                        </Modal>

                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                           <Modal.Header closeButton>
                              <Modal.Title>Confirmar Eliminación</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                              {selectedUser && (
                                 <>
                                    <p>¿Estás seguro de que deseas eliminar el siguiente usuario?</p>
                                    <ul>
                                       <li><strong>Nombres:</strong> {selectedUser.firstName}</li>
                                       <li><strong>Apellidos:</strong> {selectedUser.lastName}</li>
                                       <li><strong>Email:</strong> {selectedUser.email}</li>
                                       <li><strong>Contraseña:</strong> {selectedUser.password}</li>
                                       <li><strong>Telefono:</strong> {selectedUser.phone}</li>
                                       <li><strong>Rol:</strong> {selectedUser.role}</li>
                                    </ul>
                                 </>
                              )}
                           </Modal.Body>
                           <Modal.Footer>
                              <Button variant="secondary" onClick={() => setShowModal(false)}>
                                 Cancelar
                              </Button>
                              <Button variant="danger" onClick={confirmDelete}>
                                 Confirmar Eliminación
                              </Button>
                           </Modal.Footer>
                        </Modal>
                        
                        <div className="input-group mb-3">
                           <span className="input-group-text" id="search-input">
                              <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                 <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                           </span>
                           <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} type="search" className="form-control" placeholder="Buscar por (nombres, apellidos, correo)" />
                        </div>

                        <div className="container">
                           <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                              <table className="table table-striped table-bordered" id="basic-table">
                                 <thead>
                                    <tr className="ligth">
                                       <th>Nombre</th>
                                       <th>Apellido</th>
                                       <th>Email</th>
                                       <th>Contraseña</th>
                                       <th>Teléfono</th>
                                       <th>Rol</th>
                                       <th>Fecha</th>
                                       <th min-width="100px">Acciones</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {filteredUsers.length > 0 ? (
                                       filteredUsers.map((user) => (
                                          <tr key={user.id}>
                                             <td>{user.firstName}</td>
                                             <td>{user.lastName}</td>
                                             <td>{user.email}</td>
                                             <td>{user.password}</td>
                                             <td>{user.phone}</td>
                                             <td>{user.role}</td>
                                             <td>{user.createdAt.toDate().toLocaleDateString()}</td>

                                             <td>
                                                <Link
                                                   onClick={() => handleEdit(user)}
                                                   className="btn btn-sm btn-icon btn-warning m-1"
                                                   data-toggle="tooltip"
                                                   data-placement="top"
                                                   title="Edit"
                                                   data-original-title="Edit"
                                                   to="#"
                                                >
                                                   <span className="btn-inner">
                                                      <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                         <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                         <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                         <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                      </svg>
                                                   </span>
                                                </Link>

                                                <Link
                                                   className="btn btn-sm btn-icon btn-danger"
                                                   data-toggle="tooltip"
                                                   data-placement="top"
                                                   title="Eliminar"
                                                   to="#"
                                                   onClick={() => openModal(user)}
                                                >
                                                   <span className="btn-inner">
                                                      <svg
                                                         width="20"
                                                         viewBox="0 0 24 24"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         stroke="currentColor"
                                                      >
                                                         <path
                                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                         ></path>
                                                         <path
                                                            d="M20.708 6.23975H3.75"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                         ></path>
                                                         <path
                                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                         ></path>
                                                      </svg>
                                                   </span>
                                                </Link>


                                             </td>
                                          </tr>
                                       ))
                                    ) : (
                                       <tr>
                                          <td colSpan="6">No se encontraron usuarios</td>
                                       </tr>
                                    )}

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
            <ToastContainer position="top-end" className="p-3">
               <Toast
                  bg={toastVariant === 'success' ? 'success' : 'danger'}
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  delay={3000}
                  autohide
               >
                  <Toast.Body>{toastMessage}</Toast.Body>
               </Toast>
            </ToastContainer>
         </div>
      </>
   )

}

export default UserList;