import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Modal, ToastContainer, Toast, Form } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useLocation, Route } from 'react-router-dom';
import { collection, getDocs, doc, addDoc, updateDoc, arrayUnion, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../../firebase/firebase_settings';
import PDFGenerator from "../pdfgenerator";

const Admin = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('todos'); // 'todos', 'true', 'false'

    const [selectedContract, setSelectedContract] = useState(null);
    const [corrections, setCorrections] = useState('');

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage('');
    };

    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const location = useLocation();
    useEffect(() => {
        const fetchContracts = async () => {
            try {
                // Obtener todos los contratos de la colección 'contracts' sin ningún filtro
                const querySnapshot = await getDocs(collection(db, 'contracts'));

                const contractsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                console.log('Contratos obtenidos:', contractsData);

                // Ordenar contratos por código de contrato
                contractsData.sort((a, b) => a.contractCode.localeCompare(b.contractCode));

                setContracts(contractsData);
            } catch (err) {
                console.error('Error al obtener contratos: ', err);
                setError('Error al obtener contratos.');
            } finally {
                setLoading(false);
            }
        };

        fetchContracts();
    }, []);

    const renderHeadlines = (headlines) => {
        return headlines.map((headline, index) => (
            <div key={index}>
                Nombre: {headline.name} <br />
                Cédula: {headline.idNumber}
                {index < headlines.length - 1 ? ', ' : ''}
            </div>
        ));
    };


    const renderServices = (services) => {
        if (!Array.isArray(services)) {
            return null;

        }
        return services.map((service, index) => (
            <div key={index}>
                {service.label}
                {index < services.length - 1 ? ', ' : ''}
            </div>
        ));
    };

    const renderpaymentMethod = (paymentMethod) => {
        if (paymentMethod && typeof paymentMethod === 'object') {
            return <div>{paymentMethod.label}</div>;
        }
        return null;
    };

    const renderpaymentType = (paymentType) => {
        if (paymentType && typeof paymentType === 'object') {
            return <div>{paymentType.label}</div>;
        }
        return null;
    };

    const rendertrainingPlace = (trainingPlace) => {
        if (trainingPlace && typeof trainingPlace === 'object') {
            return <div>{trainingPlace.label}</div>;
        }
        return null;
    };

    const renderestadoVenta = (estadoVenta) => {
        if (estadoVenta && typeof estadoVenta === 'object') {
            return <div>{estadoVenta.label}</div>;
        }
        return null;
    };

    const handleReject = async () => {
        if (selectedContract) {
            try {
                const contractRef = doc(db, "contracts", selectedContract.id);

                // Actualizar el contrato con las correcciones
                await updateDoc(contractRef, {
                    corrections: corrections
                });

                // Obtener el ID del usuario
                const userId = selectedContract.id_vent;

                const client = selectedContract.client;
                const clientName = client ? `${client.name} ${client.lastname}` : null;

                // Asegurarse de que el texto de corrección siempre esté presente
                const correctionText = corrections || "";

                // Validar que los datos sean válidos antes de registrar la notificación
                if (!clientName || !userId) {
                    console.error("Faltan campos obligatorios: clientName o userId.");
                    return; // Salir si falta información necesaria
                }

                // Registrar la notificación en Firestore
                const notificationRef = collection(db, 'notifications');
                const newNotification = {
                    id_contract: selectedContract.contractCode,
                    id_user: userId,
                    client: clientName,
                    corrections: correctionText,
                    timestamp: new Date(), // Fecha y hora de la notificación
                    type: "Revision"
                };

                await addDoc(notificationRef, newNotification);

                // Actualizar el estado de los contratos localmente
                setContracts(prevContracts =>
                    prevContracts.map(c =>
                        c.id === selectedContract.id
                            ? { ...c, corrections }
                            : c
                    )
                );

                handleClose();
            } catch (error) {
                console.error("Error rechazando el contrato:", error);
            }
        }
    };

    const approveContract = async (contract) => {
        try {
            const contractRef = doc(db, "contracts", contract.id);
            await updateDoc(contractRef, { approved: true });

            // Actualiza el estado localmente
            setContracts(prevContracts =>
                prevContracts.map(c => c.id === contract.id ? { ...c, approved: true } : c)
            );

            // Obtener el ID del usuario y el cliente
            const userId = contract.id_vent;
            const client = contract.client;
            const clientName = client ? `${client.name} ${client.lastname}` : null;

            // Validar que los datos sean válidos antes de registrar la notificación
            if (!clientName || !userId) {
                console.error("Faltan campos obligatorios: clientName o userId.");
                return; // Salir si falta información necesaria
            }

            // Registrar la notificación en Firestore
            const notificationRef = collection(db, 'notifications');
            const newNotification = {
                id_contract: contract.contractCode,
                id_user: userId,
                client: clientName,
                corrections: "Contrato Aprobado", // En este caso, no hay correcciones porque es una aprobación
                timestamp: new Date(), // Fecha y hora de la notificación
                type: "Revision"
            };

            await addDoc(notificationRef, newNotification);

            console.log('Contrato aprobado y notificación registrada correctamente.');
        } catch (error) {
            console.error("Error aprobando el contrato:", error);
        }
    };


    const prepareContractDataForPDF = (contract) => {

        return {
            contractCode: contract.contractCode,
            client: {
                name: contract.client?.name,
                lastname: contract.client?.lastname,
                idnumber: contract.client?.idnumber,
                birthdate: contract.client?.birthdate,
                address: contract.client?.address,
                email: contract.client?.email,
                phone: contract.client?.phone
            },
            date: contract.date,
            observations: contract.observations,
            observationsadmin: contract.observationsadmin,

            paymentMethod: {
                label: contract.paymentMethod?.label
            },
            paymentType: {
                label: contract.paymentType?.label
            },
            trainingPlace: {
                label: contract.trainingPlace?.label
            },

            estadoVenta: contract.estadoVenta,
            valorInicialHoy: contract.valorInicialHoy,
            valorPactadoHoy: contract.valorPactadoHoy,
            valorPactadoMasCUI: contract.valorPactadoMasCUI,
            services: contract.services?.map(service => ({
                label: service.label
            })),
            headlines: contract.headlines?.map(headline => ({
                name: headline.name,
                idNumber: headline.idNumber,
                birthdate: headline.birthdate,
                photoFront: headline.photoFront,
                photoBack: headline.photoBack
            }))
        };
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredContracts = contracts.filter(contract => {
        const clientName = `${contract.client?.name || ''} ${contract.client?.lastname || ''}`.toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();

        // Filtrar por coincidencia en el nombre completo del cliente
        const nameMatches = clientName.includes(searchTermLower);

        // Filtrar por estado
        const statusMatches = statusFilter === 'todos'
            || (statusFilter === 'true' && contract.approved)
            || (statusFilter === 'false' && !contract.approved);

        return nameMatches && statusMatches;
    });

    const renderHeadlinesWithDetails = (headlines = [], services = []) => {

        if (!Array.isArray(headlines) || !Array.isArray(services)) {
            return null;
        }

        return headlines.map((headline, index) => (
            <tr key={index}>
                <td>
                    Titular: {headline.name} <br />
                    Cédula: {headline.idNumber}
                </td>
                <td>
                    {services.length > index ? services[index].label : 'N/A'}
                </td>
                <td>
                    {headline.photoFront ? (
                        <img
                            src={headline.photoFront}
                            alt="N/A"
                            style={{ width: '100px', cursor: 'pointer', marginRight: '10px' }}
                            onClick={() => handleImageClick(headline.photoFront)}
                        />
                    ) : (
                        'Sin foto frontal  '
                    )}
                    {headline.photoBack ? (
                        <img
                            src={headline.photoBack}
                            alt="N/A"
                            style={{ width: '100px', cursor: 'pointer', marginRight: '10px' }}
                            onClick={() => handleImageClick(headline.photoBack)}
                        />
                    ) : (
                        '  Sin foto trasera'
                    )}
                </td>
            </tr>
        ));
    };

    const [serviceOptions, setServiceOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newService, setNewService] = useState("");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [formData, setFormData] = useState({
        serviceName: "",
    });
    const fetchServiceOptions = async () => {
        try {
            const optionsCollection = collection(db, "OptionsSelect");
            const optionsSnapshot = await getDocs(optionsCollection);

            // Extraer serviceOptions de cada documento
            const optionsList = optionsSnapshot.docs.map(doc => {
                const data = doc.data();
                return data.serviceOptions || []; // Asegúrate de que sea un array
            });

            // Aplanar el array de arrays (si hay varios documentos)
            const allOptions = optionsList.flat();
            setServiceOptions(allOptions);
        } catch (error) {
            console.error('Error al obtener los options:', error);
        }
    };

    const handleAddService = async () => {
        if (newService.trim() === "") {
            alert("Por favor, ingresa un nombre válido para el servicio.");
            return;
        }

        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            await updateDoc(docRef, {
                serviceOptions: arrayUnion(newService)
            });

            fetchServiceOptions();
            setIsModalOpen(false);
            setNewService("");
        } catch (error) {
            console.error("Error al agregar el servicio:", error);
        }
    };


    // Abrir modal y cargar los datos del servicio seleccionado
    const handleEditService = (service) => {
        setSelectedService(service);
        setFormData({ serviceName: service });
        setIsEditModalOpen(true);
    };

    // Guardar los cambios realizados en el servicio
    const handleUpdateService = async () => {
        if (!formData.serviceName.trim()) {
            alert("El nombre del servicio no puede estar vacío.");
            return;
        }

        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            // Actualizar el array de servicios
            const updatedOptions = serviceOptions.map(service =>
                service === selectedService ? formData.serviceName : service
            );

            await updateDoc(docRef, {
                serviceOptions: updatedOptions
            });

            fetchServiceOptions(); // Refrescar los datos
            setIsEditModalOpen(false); // Cerrar el modal
        } catch (error) {
            console.error("Error al actualizar el servicio:", error);
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };
    // Abrir el modal de eliminación
    const handleDeleteService = (service) => {
        setSelectedService(service);
        setIsDeleteModalOpen(true);
    };

    // Confirmar la eliminación del servicio
    const confirmDelete = async () => {
        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            // Filtrar el servicio a eliminar
            const updatedOptions = serviceOptions.filter(service => service !== selectedService);

            await updateDoc(docRef, {
                serviceOptions: updatedOptions
            });

            fetchServiceOptions(); // Refrescar los datos
            setIsDeleteModalOpen(false); // Cerrar el modal
        } catch (error) {
            console.error("Error al eliminar el servicio:", error);
        }
    };

    const [cityOptions, setCityOptions] = useState([]);
    const [isCityModalOpen, setIsCityModalOpen] = useState(false);
    const [newCity, setNewCity] = useState("");
    const [isEditCityModalOpen, setIsEditCityModalOpen] = useState(false);
    const [isDeleteCityModalOpen, setIsDeleteCityModalOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [formDataCity, setFormDataCity] = useState({
        city: "",
    });
    const fetchCityOptions = async () => {
        try {
            const optionsCollection = collection(db, "OptionsSelect");
            const optionsSnapshot = await getDocs(optionsCollection);

            const optionsList = optionsSnapshot.docs.map(doc => {
                const data = doc.data();
                return data.cityOptions || []; // Asegúrate de que sea un array
            });

            // Aplanar el array de arrays (si hay varios documentos)
            const allOptions = optionsList.flat();
            setCityOptions(allOptions);
        } catch (error) {
            console.error('Error al obtener los options:', error);
        }
    };
    const handleAddCity = async () => {
        if (newCity.trim() === "") {
            alert("Por favor, ingresa un nombre válido para la ciudad.");
            return;
        }

        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            await updateDoc(docRef, {
                cityOptions: arrayUnion(newCity)
            });

            fetchCityOptions();
            setIsCityModalOpen(false);
            setNewCity("");
        } catch (error) {
            console.error("Error al agregar la ciudad:", error);
        }
    };
    // Abrir modal y cargar los datos del servicio seleccionado
    const handleEditCity = (city) => {
        setSelectedCity(city);
        setFormDataCity({ cityName: city });
        setIsEditCityModalOpen(true);
    };
    const handleUpdateCity = async () => {
        if (!formDataCity.cityName.trim()) {
            alert("El nombre de la ciudad no puede estar vacío.");
            return;
        }

        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            // Actualizar el array de servicios
            const updatedOptions = cityOptions.map(city =>
                city === selectedCity ? formDataCity.cityName : city
            );

            await updateDoc(docRef, {
                cityOptions: updatedOptions
            });

            fetchCityOptions(); // Refrescar los datos
            setIsEditCityModalOpen(false); // Cerrar el modal
        } catch (error) {
            console.error("Error al actualizar la ciudad:", error);
        }
    };
    const handleInputChangeCity = (e) => {
        setFormDataCity({ ...formDataCity, [e.target.id]: e.target.value });
    };
    // Abrir el modal de eliminación
    const handleDeleteCity = (city) => {
        setSelectedCity(city);
        setIsDeleteCityModalOpen(true);
    };

    // Confirmar la eliminación del servicio
    const confirmDeleteCity = async () => {
        try {
            const optionsDoc = (await getDocs(collection(db, "OptionsSelect"))).docs[0];
            const docRef = doc(db, "OptionsSelect", optionsDoc.id);

            // Filtrar el servicio a eliminar
            const updatedOptions = cityOptions.filter(city => city !== selectedCity);

            await updateDoc(docRef, {
                cityOptions: updatedOptions
            });

            fetchCityOptions(); // Refrescar los datos
            setIsDeleteCityModalOpen(false); // Cerrar el modal
        } catch (error) {
            console.error("Error al eliminar la ciudad:", error);
        }
    };
    useEffect(() => {
        fetchServiceOptions();
        fetchCityOptions();
    }, []);

    const fetchClientPhotos = async (idnumber) => {
        try {
            const clientQuery = query(
                collection(db, 'clients'),
                where('idnumber', '==', idnumber)
            );
            const querySnapshot = await getDocs(clientQuery);

            if (!querySnapshot.empty) {
                const clientData = querySnapshot.docs[0].data();
                return {
                    photoFront: clientData.photoFront || null,
                    photoBack: clientData.photoBack || null,
                };
            } else {
                console.warn('Cliente no encontrado');
                return { photoFront: null, photoBack: null };
            }
        } catch (err) {
            console.error('Error al obtener fotos del cliente: ', err);
            return { photoFront: null, photoBack: null };
        }
    };
    useEffect(() => {
        const fetchPhotosForSelectedContract = async () => {
            if (selectedContract?.client?.idnumber) {
                const clientPhotos = await fetchClientPhotos(selectedContract.client.idnumber);

                setSelectedContract((prevContract) => ({
                    ...prevContract,
                    client: {
                        ...prevContract.client,
                        photoFront: clientPhotos.photoFront,
                        photoBack: clientPhotos.photoBack,
                    },
                }));
            }
        };

        fetchPhotosForSelectedContract();
    }, [selectedContract]);


    return (
        <>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Lista de Contratos</h4>
                            </div>


                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive mt-4">

                                <div className="m-3">
                                    <Form.Check
                                        type="radio"
                                        label="Todos"
                                        name="statusFilter"
                                        id="statusAll"
                                        checked={statusFilter === 'todos'}
                                        onChange={() => setStatusFilter('todos')}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Aprobado"
                                        name="statusFilter"
                                        id="statusApproved"
                                        checked={statusFilter === 'true'}
                                        onChange={() => setStatusFilter('true')}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Pendiente"
                                        name="statusFilter"
                                        id="statusPending"
                                        checked={statusFilter === 'false'}
                                        onChange={() => setStatusFilter('false')}
                                        inline
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="search-input">
                                        <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                            <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <input value={searchTerm} onChange={handleSearchChange}
                                        type="search" className="form-control" placeholder="Buscar por Cliente" />
                                </div>

                                {loading ? (
                                    <div>Cargando...</div>
                                ) : error ? (
                                    <div>{error}</div>
                                ) : (

                                    <div className="container">
                                        <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                            <table className="table table-striped table-bordered" id="basic-table">
                                                <thead>
                                                    <tr>
                                                        <th>Estado</th>
                                                        <th>Código</th>
                                                        <th>Fecha</th>
                                                        <th>Cliente</th>
                                                        <th>Titulares</th>
                                                        <th>Servicios</th>
                                                        <th>Tipo</th>
                                                        <th>Tipo de Pago</th>
                                                        <th>Valor Pactado Hoy</th>
                                                        <th>Valor Inicial Hoy</th>
                                                        <th>Valor Pactado más CUI</th>
                                                        <th>Estado de Venta</th>
                                                        <th>Forma de Pago</th>
                                                        <th>Observaciones al cliente</th>
                                                        <th>Observaciones al administrador</th>
                                                        <th>Correciones</th>

                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredContracts.length > 0 ? (
                                                        filteredContracts.map((contract) => (
                                                            <tr key={contract.id}>
                                                                <td>{contract.approved ? 'Aprobado' : 'Pendiente'}</td>
                                                                <td>{contract.contractCode}</td>
                                                                <td>{contract.date}</td>
                                                                <td>{contract.client.name} {contract.client.lastname}</td>
                                                                <td>{renderHeadlines(contract.headlines)}</td>
                                                                <td>{renderServices(contract.services)}</td>
                                                                <td>{rendertrainingPlace(contract.trainingPlace)}</td>
                                                                <td>{renderpaymentType(contract.paymentType)}</td>
                                                                <td>{contract.valorPactadoHoy}</td>
                                                                <td>{contract.valorInicialHoy}</td>
                                                                <td>{contract.valorPactadoMasCUI}</td>
                                                                <td>{renderestadoVenta(contract.estadoVenta)}</td>
                                                                <td>{renderpaymentMethod(contract.paymentMethod)}</td>
                                                                <td>{contract.observations}</td>
                                                                <td>{contract.observationsadmin}</td>
                                                                <td>{contract.corrections}</td>

                                                                <td>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() => {
                                                                            setSelectedContract(contract);

                                                                        }}
                                                                    >
                                                                        Imagenes
                                                                    </Button>{' '}
                                                                    <Button
                                                                        variant="success"
                                                                        onClick={() => approveContract(contract)}
                                                                    >
                                                                        {contract.approved ? 'Aprobado' : 'Aprobar'}
                                                                    </Button>{' '}
                                                                    <Button
                                                                        onClick={() => {
                                                                            setSelectedContract(contract);
                                                                            setShow(true);
                                                                        }}
                                                                        variant="danger"
                                                                    >
                                                                        Rechazar
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="19" className="text-center">No se encontraron contratos.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Detalles del contrato</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">

                            <div className="container mt-4">
                                <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-bordered" id="basic-table">
                                        <thead>
                                            <tr>
                                                <th>Datos</th>
                                                <th>Servicios</th>
                                                <th>Fotos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedContract ? (
                                                <>
                                                    <tr>
                                                        <td colSpan="6">
                                                            Cliente: {selectedContract.client.name} {selectedContract.client.lastname}
                                                        </td>
                                                    </tr>

                                                    {renderHeadlinesWithDetails(
                                                        selectedContract.headlines || [],
                                                        selectedContract.services || []
                                                    )}

                                                    <tr>
                                                        <td colSpan="6">
                                                            <p>Foto del pago:</p>
                                                            {Array.isArray(selectedContract.photoPago) && selectedContract.photoPago.length > 0 ? (
                                                                selectedContract.photoPago.map((url, index) => (
                                                                    <img
                                                                        key={index}
                                                                        src={url}
                                                                        alt={`Pago ${index + 1}`}
                                                                        style={{ width: '100px', cursor: 'pointer', margin: '5px' }}
                                                                        onClick={() => handleImageClick(url)}
                                                                    />
                                                                ))
                                                            ) : (
                                                                <p>No hay fotos de pago disponibles.</p>
                                                            )}
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td colSpan="6">
                                                            <p>Fotos de la Cédula:</p>
                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                {selectedContract.client.photoFront && (
                                                                    <div>
                                                                        <p>Parte Frontal:</p>
                                                                        <img
                                                                            src={selectedContract.client.photoFront}
                                                                            alt="Cédula Parte Frontal"
                                                                            style={{ width: '100px', cursor: 'pointer', margin: '5px' }}
                                                                            onClick={() => handleImageClick(selectedContract.client.photoFront)}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {selectedContract.client.photoBack && (
                                                                    <div>
                                                                        <p>Parte Trasera:</p>
                                                                        <img
                                                                            src={selectedContract.client.photoBack}
                                                                            alt="Cédula Parte Trasera"
                                                                            style={{ width: '100px', cursor: 'pointer', margin: '5px' }}
                                                                            onClick={() => handleImageClick(selectedContract.client.photoBack)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="6">
                                                            <Button
                                                                variant="success"
                                                                onClick={() => approveContract(selectedContract)}
                                                            >
                                                                {selectedContract.approved ? 'Aprobado' : 'Aprobar'}
                                                            </Button>{' '}
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedContract(selectedContract);
                                                                    setShow(true);
                                                                }}
                                                                variant="danger"
                                                            >
                                                                Rechazar
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">No se encontraron contratos.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Servicios</h4>
                            </div>
                            <button class="btn btn-primary" onClick={() => setIsModalOpen(true)}>
                                Nuevo servicio
                            </button>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="container">
                                <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-bordered" id="basic-table">
                                        <thead>
                                            <tr className="ligth">
                                                <th>N°</th>
                                                <th>Nombre</th>
                                                <th min-width="100px">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {serviceOptions.map((option, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{option}</td>

                                                    <td>
                                                        <Link
                                                            onClick={() => handleEditService(option)}
                                                            className="btn btn-sm btn-icon btn-warning m-1"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Edit"
                                                            data-original-title="Edit"
                                                            to="#"
                                                        >
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </Link>

                                                        <Link
                                                            onClick={() => handleDeleteService(option)}
                                                            className="btn btn-sm btn-icon btn-danger"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Eliminar"
                                                            to="#"
                                                        >
                                                            <span className="btn-inner">
                                                                <svg
                                                                    width="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                    <path
                                                                        d="M20.708 6.23975H3.75"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                    <path
                                                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </Link>


                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal usando React-Bootstrap */}
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Nuevo Servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="newService">Nuevo Servicio</Form.Label>
                        <Form.Control
                            type="text"
                            id="newService"
                            placeholder="Ingresa el nombre del nuevo servicio"
                            value={newService}
                            onChange={(e) => setNewService(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddService}>
                        Guardar Servicio
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para editar un servicio */}
            <Modal show={isEditModalOpen} onHide={() => setIsEditModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label>Nombre del Servicio</label>
                            <input
                                type="text"
                                id="serviceName"
                                value={formData.serviceName}
                                onChange={handleInputChange}
                                className="form-control"
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsEditModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleUpdateService}>
                        Actualizar Servicio
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para confirmar eliminación */}
            <Modal show={isDeleteModalOpen} onHide={() => setIsDeleteModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedService && (
                        <>
                            <p>¿Estás seguro de que deseas eliminar el siguiente servicio?</p>
                            <ul>
                                <li><strong>Servicio:</strong> {selectedService}</li>
                            </ul>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsDeleteModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Confirmar Eliminación
                    </Button>
                </Modal.Footer>
            </Modal>


            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Ciudades</h4>
                            </div>
                            <button class="btn btn-primary" onClick={() => setIsCityModalOpen(true)}>
                                Nueva Ciudad
                            </button>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="container">
                                <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <table className="table table-striped table-bordered" id="basic-table">
                                        <thead>
                                            <tr className="ligth">
                                                <th>N°</th>
                                                <th>Nombre</th>
                                                <th min-width="100px">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {cityOptions.map((option, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{option}</td>

                                                    <td>
                                                        <Link
                                                            onClick={() => handleEditCity(option)}
                                                            className="btn btn-sm btn-icon btn-warning m-1"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Edit"
                                                            data-original-title="Edit"
                                                            to="#"
                                                        >
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </Link>

                                                        <Link
                                                            onClick={() => handleDeleteCity(option)}

                                                            className="btn btn-sm btn-icon btn-danger"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Eliminar"
                                                            to="#"
                                                        >
                                                            <span className="btn-inner">
                                                                <svg
                                                                    width="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                    <path
                                                                        d="M20.708 6.23975H3.75"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                    <path
                                                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </Link>


                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal usando React-Bootstrap */}
            <Modal show={isCityModalOpen} onHide={() => setIsCityModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Nueva Ciudad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="newCity">Nueva Ciudad</Form.Label>
                        <Form.Control
                            type="text"
                            id="newCity"
                            placeholder="Ingresa el nombre de la ciudad"
                            value={newCity}
                            onChange={(e) => setNewCity(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsCityModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddCity}>
                        Guardar Servicio
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para editar una ciudad */}
            <Modal show={isEditCityModalOpen} onHide={() => setIsEditCityModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Ciudad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label>Nombre de la Ciudad</label>
                            <input
                                type="text"
                                id="cityName"
                                value={formDataCity.cityName}
                                onChange={handleInputChangeCity}
                                className="form-control"
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsEditCityModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleUpdateCity}>
                        Actualizar Ciudad
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para confirmar eliminación */}
            <Modal show={isDeleteCityModalOpen} onHide={() => setIsDeleteCityModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedService && (
                        <>
                            <p>¿Estás seguro de que deseas eliminar la siguiente ciudad?</p>
                            <ul>
                                <li><strong>Ciudad:</strong> {selectedCity}</li>
                            </ul>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsDeleteCityModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteCity}>
                        Confirmar Eliminación
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="modal-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">Vista de Imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={modalImage}
                        alt="Imagen ampliada"
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '80vh',
                            objectFit: 'contain'
                        }}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contrato Rechazado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Correcciones</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={corrections}
                            onChange={(e) => setCorrections(e.target.value)}
                            placeholder="Escribe las correcciones"
                        />
                    </Form.Group>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleReject}>
                            Agregar Observación
                        </Button>
                    </Modal.Footer>

                </Modal.Body>
            </Modal>
        </>

    );
};

export default Admin;