
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyAFMLr28xnkLdbSk0g2l6xFSg0VobEoap4",
  authDomain: "stroit-corp.firebaseapp.com",
  projectId: "stroit-corp",
  storageBucket: "stroit-corp.appspot.com",
  messagingSenderId: "617113777034",
  appId: "1:617113777034:web:388ad96f0d4758b97c0e73",
  measurementId: "G-RGPFXYVM6H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


export { db,storage };
