import React, { useState, useContext, memo, Fragment, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion, useAccordionButton, AccordionContext } from 'react-bootstrap';

import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { firebaseConfig } from '../../../../firebase/firebase_settings';
import { initializeApp } from 'firebase/app';

initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

function CustomToggle({ children, eventKey, onClick }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey);
        }}>
            {children}
        </Link>
    );
}

const VerticalNav = memo((props) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [active, setActive] = useState('');
    const [userRole, setUserRole] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado

    useEffect(() => {
        const fetchUserRole = async () => {
            if (userId) {
                const userRef = doc(db, "users", userId);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserRole(userData.role);  // Guardar el rol del usuario
                } else {
                    console.log("No se encontró el documento del usuario.");
                    navigate("/");
                }
            } else {
                console.log("No se encontró el ID del usuario en sessionStorage.");
                navigate("/");
            }
        };

        fetchUserRole();
    }, [navigate]);



    if (userRole === null) {
        return <div>Cargando...</div>;
    }

    return (
        <Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu">
                <li><hr className="hr-horizontal" /></li>
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">Menu</span>
                        <span className="mini-icon">-</span>
                    </Link>
                </li>

                {userRole === 'admin' ? (
                    <>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/app/user-list' ? 'active' : ''} nav-link`} to="/dashboard/app/user-list">
                                <i className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 2L2 8h1v8h4V12h6v4h4V8h1L10 2z" />
                                    </svg>
                                </i>
                                <i className="sidenav-mini-icon"> U </i>
                                <span className="item-name">Vendedores</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/admin/admin' ? 'active' : ''} nav-link`} to="/dashboard/admin/admin">
                                <i className="icon">
                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.7688 8.71387H16.2312C18.5886 8.71387 20.5 10.5831 20.5 12.8885V17.8254C20.5 20.1308 18.5886 22 16.2312 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8254V12.8885C3.5 10.5831 5.41136 8.71387 7.7688 8.71387ZM11.9949 17.3295C12.4928 17.3295 12.8891 16.9419 12.8891 16.455V14.2489C12.8891 13.772 12.4928 13.3844 11.9949 13.3844C11.5072 13.3844 11.1109 13.772 11.1109 14.2489V16.455C11.1109 16.9419 11.5072 17.3295 11.9949 17.3295Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M17.523 7.39595V8.86667C17.1673 8.7673 16.7913 8.71761 16.4052 8.71761H15.7447V7.39595C15.7447 5.37868 14.0681 3.73903 12.0053 3.73903C9.94257 3.73903 8.26594 5.36874 8.25578 7.37608V8.71761H7.60545C7.20916 8.71761 6.83319 8.7673 6.47754 8.87661V7.39595C6.4877 4.41476 8.95692 2 11.985 2C15.0537 2 17.523 4.41476 17.523 7.39595Z" fill="currentColor"></path>
                                    </svg>
                                </i>
                                <span className="item-name">Aprobar Contratos</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/table/contracts-table' ? 'active' : ''} nav-link`} to="/dashboard/table/contracts-table">
                                <i className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M0 0h20v20H0V0zm5 14h2v-4H5v4zm4 0h2v-8H9v8zm4 0h2v-6h-2v6z" />
                                    </svg>
                                </i>
                                <i className="sidenav-mini-icon"> B </i>
                                <span className="item-name">Contratos</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/table/clients-table' ? 'active' : ''} nav-link`} to="/dashboard/table/clients-table">
                                <i className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 0a5 5 0 1 0 0 10 5 5 0 0 0 0-10zM10 11c-4.418 0-8 2.686-8 6v1h16v-1c0-3.314-3.582-6-8-6z" />
                                    </svg>

                                </i>
                                <i className="sidenav-mini-icon"> D </i>
                                <span className="item-name">Base Clientes</span>
                            </Link>
                        </li>
                    </>
                ) : userRole === 'user' ? (

                    <>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/table/contracts-table' ? 'active' : ''} nav-link`} to="/dashboard/table/contracts-table">
                                <i className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M0 0h20v20H0V0zm5 14h2v-4H5v4zm4 0h2v-8H9v8zm4 0h2v-6h-2v6z" />
                                    </svg>
                                </i>
                                <i className="sidenav-mini-icon"> B </i>
                                <span className="item-name">Contratos</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${location.pathname === '/dashboard/table/clients-table' ? 'active' : ''} nav-link`} to="/dashboard/table/clients-table">
                                <i className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 0a5 5 0 1 0 0 10 5 5 0 0 0 0-10zM10 11c-4.418 0-8 2.686-8 6v1h16v-1c0-3.314-3.582-6-8-6z" />
                                    </svg>
                                </i>
                                <i className="sidenav-mini-icon"> D </i>
                                <span className="item-name">Base Clientes</span>
                            </Link>
                        </li>

                    </>
                ) : (
                    <div>No tienes acceso a esta página</div>
                )}
            </Accordion>
        </Fragment>
    );
});

export default VerticalNav;
