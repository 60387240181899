import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Form, Pagination, Toast, ToastContainer } from "react-bootstrap";
import { FaEdit, FaPlus, FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import Card from "../../../components/Card";
import { db } from "../../../firebase/firebase_settings";
import { collection, getDocs, doc, getDoc, updateDoc, addDoc, query, where } from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import CameraCapture from '../CameraCapture';

import './client.css';


const calculateAge = (birthdate) => {
  if (!birthdate) return '';
  // Convertir la fecha de 'dd/mm/yyyy' a 'yyyy-mm-dd'
  const [day, month, year] = birthdate.split('/');
  const birthDateFormatted = `${year}-${month}-${day}`;
  const birthDate = new Date(birthDateFormatted);

  // Verificar si la fecha es válida
  if (isNaN(birthDate)) {
    console.error("Fecha inválida:", birthDateFormatted);
    return '';
  }
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [formData, setFormData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('danger');
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const sellerId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado
  const [photoFront, setPhotoFront] = useState('');
  const [photoBack, setPhotoBack] = useState('');
  const [showCamera, setShowCamera] = useState(false); // Initialize to false
  const [currentPhotoType, setCurrentPhotoType] = useState('front'); // To track if we are capturing front or back


  useEffect(() => {
    const fetchClients = async () => {
      try {

        if (!sellerId) {
          setToastMessage('No se pudo obtener el ID del usuario.');
          setToastVariant('danger');
          setShowToast(true);
          return;
        }

        // Obtén el rol del usuario desde la colección 'users'
        const userDoc = doc(db, "users", sellerId);
        const userSnapshot = await getDoc(userDoc);
        const userRole = userSnapshot.exists() ? userSnapshot.data().role : null;

        if (!userRole) {
          setToastMessage('No se pudo obtener el rol del usuario.');
          setToastVariant('danger');
          setShowToast(true);
          return;
        }

        const clientsCollection = collection(db, "clients");
        let q;

        // Si es admin, obtener todos los clientes
        if (userRole === "admin") {
          q = query(clientsCollection); // Consulta sin filtro para obtener todos los clientes
        } else if (userRole === "user") {
          // Si es usuario normal, obtener solo sus clientes
          q = query(clientsCollection, where("id_vent", "==", sellerId));
        }

        const clientsSnapshot = await getDocs(q);
        const clientsList = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsList);
      } catch (error) {
        console.error("Error fetching clients: ", error);
        setToastMessage('Error al obtener los clientes.');
        setToastVariant('danger');
        setShowToast(true);
      }
    };

    fetchClients();
  }, [refreshTable]);

  const refreshClientsTable = () => {
    setRefreshTable(prevState => !prevState); // Cambia el estado para disparar useEffect
  };
  const handleEdit = async (id) => {
    console.log(id);
    try {
      const clientDoc = doc(db, "clients", id);
      const clientSnapshot = await getDoc(clientDoc);

      if (clientSnapshot.exists()) {
        const clientData = clientSnapshot.data();
        setCurrentClient({
          id,
          ...clientData
        });
        setFormData({
          ...clientData,
          birthdate: formatDateToInput(clientData.birthdate),
          photoFront: photoFront,
          photoBack: photoBack
        });
        setIsEditing(true);
        setShowModal(true);
      } else {
        setToastMessage('Cliente no encontrado');
        setToastVariant('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error("Error fetching client: ", error);
      setToastMessage('Error fetching client data');
      setToastVariant('danger');
      setShowToast(true);
    }
  };


  const handleAddClient = () => {
    setFormData({});
    setCurrentClient(null);
    setIsEditing(false);
    setShowModal(true);
  };

  const formatDateToInput = (dateStr) => {
    if (!dateStr) return '';
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const formatDateToDatabase = (dateStr) => {
    if (!dateStr) return '';
    const [year, month, day] = dateStr.split('-');
    return `${day}/${month}/${year}`;
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const checkIdnumberExists = async (idnumber) => {
    const clientsCollection = collection(db, "clients");
    const q = query(clientsCollection, where("idnumber", "==", idnumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, lastname, idnumber, phone, email, address, birthdate } = formData;
    if (!name || !lastname || !idnumber || !phone || !email || !address || !birthdate) {
      setToastMessage('Todos los campos son obligatorios');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }
  
    const age = calculateAge(birthdate);
    if (age < 18) {
      setToastMessage('La persona debe ser mayor de edad');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }
  
    const idnumberAsNumber = Number(idnumber);
    const idnumberExists = await checkIdnumberExists(idnumberAsNumber);
    if (idnumberExists && (!isEditing || (isEditing && currentClient.idnumber !== idnumberAsNumber))) {
      setToastMessage('La cédula ya existe');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }
  
    if (!sellerId) {
      setToastMessage('No se pudo obtener el ID del vendedor.');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }
  
    try {
      const clientData = {
        ...formData,
        idnumber: idnumberAsNumber,
        birthdate: formatDateToDatabase(formData.birthdate),
        id_vent: sellerId,
        photoFront: photoFront ,
        photoBack: photoBack 
      };
  
      if (isEditing && currentClient) {
        const clientDoc = doc(db, "clients", currentClient.id);
        await updateDoc(clientDoc, clientData);
      } else {
        await addDoc(collection(db, "clients"), clientData);
      }
  
      refreshClientsTable();
      handleCloseModal();
      setToastMessage('Cliente guardado exitosamente');
      setToastVariant('success');
      setShowToast(true);
    } catch (error) {
      console.error("Error saving client: ", error);
      setToastMessage('Error al guardar el cliente');
      setToastVariant('danger');
      setShowToast(true);
    }
  };


  // Sorting Logic
  const sortedClients = [...clients].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  // Pagination Logic
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = sortedClients.slice(indexOfFirstClient, indexOfLastClient);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(clients.length / clientsPerPage);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  const handlePhotoCapture = async (photoUrl) => {
    if (!formData.idnumber) {
      setToastMessage('Error: Por favor, ingrese el número de identificación antes de capturar la foto.');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }
  
    const storage = getStorage();
    const idnumber = formData.idnumber;
    let filePath = '';
  
    if (currentPhotoType === 'front') {
      filePath = `photosClient/${idnumber}/front.jpg`;
    } else if (currentPhotoType === 'back') {
      filePath = `photosClient/${idnumber}/back.jpg`;
    }
  
    const storageRef = ref(storage, filePath);
  
    try {
      // Subir la imagen
      await uploadString(storageRef, photoUrl, 'data_url');
      
      // Obtener la URL de descarga
      const downloadURL = await getDownloadURL(storageRef);
  
  
      if (currentPhotoType === 'front') {
        setPhotoFront(downloadURL);
      } else if (currentPhotoType === 'back') {
        setPhotoBack(downloadURL);
      }
  
      setShowCamera(false);
      setToastMessage('Foto capturada y guardada correctamente.');
      setToastVariant('success');
    } catch (error) {
      console.error('Error al subir la foto a Firebase:', error);
      setToastMessage(`Error al capturar o guardar la foto: ${error.message}`);
      setToastVariant('danger');
    }
  
    setShowToast(true);
  };


  const handleCapture = (type) => {
    setCurrentPhotoType(type);
    setShowCamera(true);
  };


  return (
    <>
      <Row className="mt-5">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h4 className="card-title mb-0">Lista de Clientes</h4>
            <Button
              variant="info"
              onClick={handleAddClient}
              aria-label="Add Client"
            >
              <FaPlus /> Añadir Cliente
            </Button>
          </Card.Header>

          <Card.Body>
            <div className="border-bottom my-3">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th onClick={() => handleSort('name')}>
                      Nombre {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th onClick={() => handleSort('lastname')}>
                      Apellido {sortConfig.key === 'lastname' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th onClick={() => handleSort('idnumber')}>
                      Cedula o Ruc {sortConfig.key === 'idnumber' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th onClick={() => handleSort('phone')}>
                      Teléfono {sortConfig.key === 'phone' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th onClick={() => handleSort('email')}>
                      Correo electrónico {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th onClick={() => handleSort('address')}>
                      Dirección {sortConfig.key === 'address' && (sortConfig.direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />)}
                    </th>
                    <th>
                      Edad
                    </th>
                    <th>
                      Foto cedula Frontal
                    </th>
                    <th>
                      Foto cedula Posterior
                    </th>
                    <th>
                      Acción
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentClients.map(client => (
                    <tr key={client.id}>
                      <td>{client.name}</td>
                      <td>{client.lastname}</td>
                      <td>{client.idnumber}</td>
                      <td>{client.phone}</td>
                      <td>{client.email}</td>
                      <td>{client.address}</td>
                      <td>{calculateAge(client.birthdate)}</td>
                      <td>
                        {client.photoFront ? (
                          <img src={client.photoFront}  style={{ width: '80px', height: '80px' }} />
                        ) : (
                          <span>No existe foto Frontal</span>
                        )}
                      </td>
                      <td>
                        {client.photoBack ? (
                          <img src={client.photoBack}  style={{ width: '80px', height: '80px' }} />
                        ) : (
                          <span>No existe foto Posterior</span>
                        )}
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleEdit(client.id)}
                          aria-label="Edit"
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row className="align-items-center">
                <Col md="6">
                  <div className="dataTables_info" role="status" aria-live="polite">
                    Showing {currentClients.length} of {clients.length} entries
                  </div>
                </Col>
                <Col md="6">
                  <Pagination>
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(number => (
                      <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  </Pagination>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {isEditing ? 'Edit Client' : 'Añadir Nuevo Cliente'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Reutilizamos el mismo formulario */}
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label className={formData.name ? 'highlight-label' : ''}>
                Nombre
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name || ''}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLastname" className="mb-3">
              <Form.Label>
                Apellido
              </Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={formData.lastname || ''}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formIdnumber" className="mb-3">
              <Form.Label>
                Cedula o Ruc
              </Form.Label>
              <Form.Control
                type="text"
                name="idnumber"
                value={formData.idnumber || ''}
                onChange={handleChange}
                maxLength={13}
                pattern="\d*"
                required
                inputMode="number"
              />
            </Form.Group>
            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>
                Teléfono
              </Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone || ''}
                onChange={handleChange}
                maxLength={10}
                pattern="\d*"
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>
                Correo electrónico
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>
                Dirección
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBirthdate" className="mb-3">
              <Form.Label>
                Fecha de Nacimiento
              </Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                value={formData.birthdate || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-center mb-3 gap-3">
              <Button
                variant="secondary"
                onClick={() => handleCapture('front', formData.idnumber)}
              >
                Capturar Foto Frontal
              </Button>

              <Button
                variant="secondary"
                onClick={() => handleCapture('back', formData.idnumber)}

              >
                Capturar Foto Trasera
              </Button>

            </div>
            <Button variant="primary" type="submit" className="w-100">
              {isEditing ? 'Save Changes' : 'Añadir'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {showCamera && (
        <CameraCapture
          show={showCamera}
          onCapture={handlePhotoCapture}
          onClose={() => setShowCamera(false)}
        />
      )}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ClientsTable;
