import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card, ToastContainer, Toast } from 'react-bootstrap';
import { db } from '../../../firebase/firebase_settings';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, updateDoc, getDocs, query, where, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import Select from 'react-select';
import CameraCapture from '../CameraCapture';
import { useNavigate } from 'react-router-dom';
import PDFGenerator from '../pdfgenerator';



const UpdateContract = () => {
  const [contractCode, setContractCode] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [client, setClient] = useState({ name: '', lastname: '', idnumber: '', phone: '', email: '' });
  const [headlines, setHeadlines] = useState([{ name: '', birthdate: '', idNumber: '', photoFront: '', photoBack: '', photoPago: '' }]);
  const [showToast, setShowToast] = useState(false);
  const [photoPago, setPhotoPago] = useState(''); // Inicializar el estado para photoPago
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTrainingPlace, setSelectedTrainingPlace] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [valorPactadoHoy, setValorPactadoHoy] = useState('');
  const [valorInicialHoy, setValorInicialHoy] = useState('');
  const [valorPactadoMasCUI, setValorPactadoMasCUI] = useState('');
  const [estadoVenta, setEstadoVenta] = useState([]);
  const [selectedestadoVenta, setSelectedestadoVenta] = useState(null);
  const [ciudadOptions, setCiudadOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);
  const [observations, setObservations] = useState('');
  const [observationsadmin, setObservationsadmin] = useState('');
  const [selectCiudad, setSelectedCiudad] = useState([]);
  const [corrections, setCorrections] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [trainingPlaceOptions, setTrainingPlaceOptions] = useState([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [contractData, setContractData] = useState(null);
  const [capturedStates, setCapturedStates] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [currentPhotoType, setCurrentPhotoType] = useState('');
  const [currentTitularIndex, setCurrentTitularIndex] = useState(null);
  const navigate = useNavigate();

  const { id } = useParams();

  const sellerId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado
  // Función para generar un ID único para el usuario
  const generateRandomId = () => {
    return 'notificacion_' + Math.random().toString(36).substr(2, 9);
  };
  const notificationType = 'Notificación de pago'; // Tipo de notificación
  // Función para calcular saldo faltante y gestionar notificación
  const calcularSaldoFaltante = async () => {
    const saldo = parseFloat(valorPactadoHoy) - parseFloat(valorInicialHoy);
    const newNotificationId = generateRandomId();
    const clientName = client ? `${client.name} ${client.lastname}` : null;
    if (saldo > 0) {
      // Verificar si ya existe una notificación con el mismo id_contract y tipo 'Notificación de pago'
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef,
        where('id_contract', '==', contractCode),
        where('type', '==', notificationType) // Verificamos también por tipo
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Si existe una notificación de este tipo, actualizamos la primera coincidencia
        const notificationDoc = querySnapshot.docs[0];
        const notificationRef = doc(db, 'notifications', notificationDoc.id);
        try {
          await updateDoc(notificationRef, {
            corrections: `Saldo faltante de: ${saldo.toFixed(2)}`, // Actualizar saldo
            timestamp: new Date(), // Actualizar fecha
          });
          console.log("Notificación actualizada con éxito");
        } catch (e) {
          console.error("Error al actualizar la notificación:", e);
        }
      } else {
        // Si no existe, creamos una nueva notificación
        const newNotification = {
          id_contract: contractCode,
          id_user: sellerId,
          client: clientName,
          corrections: `Saldo faltante de: ${saldo.toFixed(2)}`,
          type: notificationType,
          timestamp: new Date(),
          id_notification: newNotificationId,
        };
        try {
          await addDoc(notificationsRef, newNotification);
          console.log("Notificación guardada con éxito:", newNotification);
        } catch (e) {
          console.error("Error al guardar la notificación:", e);
        }
      }
    } else {
      console.log("No hay saldo pendiente");
    }
  };


  useEffect(() => {
    fetchOptions();
    if (id) {
      fetchContract(id);
    }
  }, [id]);

  const formatOptions = (optionsObject) => {
    return Object.keys(optionsObject).map((key) => ({
      value: key,
      label: optionsObject[key]
    }));
  };

  const fetchOptions = async () => {
    try {
      const optionsSnapshot = await getDocs(collection(db, 'OptionsSelect'));
      if (!optionsSnapshot.empty) {
        const optionsData = optionsSnapshot.docs[0].data();


        // Asegúrate de que cada campo sea un objeto
        const serviceOptions = formatOptions(optionsData.serviceOptions || {});
        const trainingPlaceOptions = formatOptions(optionsData.trainingPlaceOptions || {});
        const paymentTypeOptions = formatOptions(optionsData.paymentTypeOptions || {});
        const paymentMethodOptions = formatOptions(optionsData.paymentMethodOptions || {});
        const estadoVenta = formatOptions(optionsData.estadoVenta || {});
        const ciudadOptions = formatOptions(optionsData.cityOptions || {});
        setServiceOptions(serviceOptions);
        setTrainingPlaceOptions(trainingPlaceOptions);
        setPaymentTypeOptions(paymentTypeOptions);
        setPaymentMethodOptions(paymentMethodOptions);
        setEstadoVenta(estadoVenta);
        setCiudadOptions(ciudadOptions);
      } else {
        console.warn('No se encontraron documentos en la colección OptionsSelect.');
      }
    } catch (error) {
      console.error('Error al obtener las opciones de la base de datos:', error);
    }
  };

  const fetchContract = async (id) => {
    console.log('Fetching contract with id:', id);
    try {
      const docRef = doc(db, 'contracts', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setContractData(data);
        setCorrections(data.corrections);
        setClient(data.client);
        setContractCode(data.contractCode);
        setHeadlines(data.headlines);
        setSelectedServices(data.services || []);
        setSelectedTrainingPlace(data.trainingPlace);
        setSelectedCiudad(data.ciudad || []);
        setSelectedPaymentType(data.paymentType);
        setValorPactadoHoy(data.valorPactadoHoy);
        setValorInicialHoy(data.valorInicialHoy);
        setValorPactadoMasCUI(data.valorPactadoMasCUI);
        setSelectedestadoVenta(data.estadoVenta);
        setSelectedPaymentMethod(data.paymentMethod || []);
        setObservations(data.observations || '');
        if (data.photoPago) setPhotoPago(data.photoPago); // Establecer photoPago si existe
      } else {
        console.warn('No se encontró el contrato con el ID proporcionado.');
      }
    } catch (error) {
      console.error('Error al obtener el contrato:', error);
    }
  };

  const handleUpdateContract = async (event) => {
    event.preventDefault();

    try {
      const docRef = doc(db, 'contracts', id);
      await updateDoc(docRef, {
        client: {
          name: client.name,
          lastname: client.lastname,
          idnumber: client.idnumber,
          phone: client.phone,
          email: client.email
        },
        headlines,
        ciudad: selectCiudad,
        photoPago, // Incluir photoPago en la actualización
        services: selectedServices,
        trainingPlace: selectedTrainingPlace,
        paymentType: selectedPaymentType,

        valorPactadoHoy,
        valorInicialHoy,
        valorPactadoMasCUI,
        estadoVenta: selectedestadoVenta,
        paymentMethod: selectedPaymentMethod,
        observations,
        observationsadmin,
        corrections
      });

      const pdfData = {
        date: currentDate,
        client,
        headlines,
        ciudad: selectCiudad,
        services: selectedServices,
        trainingPlace: selectedTrainingPlace,
        paymentType: selectedPaymentType,
        valorPactadoHoy,
        valorInicialHoy,
        valorPactadoMasCUI,
        estadoVenta: selectedestadoVenta,
        paymentMethod: selectedPaymentMethod,
        observations,
        observationsadmin
      };
      calcularSaldoFaltante();
      setToastMessage('Contrato actualizado con éxito.');
      setToastVariant('success');
      setShowToast(true);
      // Generar el PDF usando la función PDFGenerator
      await PDFGenerator(pdfData);
      setTimeout(() => {
        navigate('/dashboard/table/contracts-table');
      }, 1600);
    } catch (error) {
      console.error('Error al actualizar el contrato: ', error);
      setToastMessage('Error al actualizar el contrato.');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const handleTitularChange = (index, e) => {
    const { name, value } = e.target;
    const newHeadlines = [...headlines];
    newHeadlines[index][name] = value;
    setHeadlines(newHeadlines);
  };

  const handleAddTitular = () => {
    setHeadlines([...headlines, { name: '', birthdate: '', idNumber: '', photoFront: '', photoBack: '' }]);
    setCapturedStates([...capturedStates, { front: false, back: false }]);
  };

  const handleRemoveTitular = (index) => {
    const newHeadlines = headlines.filter((_, i) => i !== index);
    setHeadlines(newHeadlines);
    const newCapturedStates = capturedStates.filter((_, i) => i !== index);
    setCapturedStates(newCapturedStates);
  };

  const handleCapture = (type, index) => {
    setCurrentPhotoType(type);
    setCurrentTitularIndex(index);
    setShowCamera(true);
  };

  const handlePhotoCapture = async (photoUrl) => {
    console.log(photoUrl)
    const storage = getStorage();
    const timestamp = new Date().getTime();
    let filePath = '';

    if (!client.idnumber) {
      console.error("El número de identificación del cliente es necesario para guardar las fotos.");
      setToastMessage('Error: El número de identificación del cliente es necesario para guardar las fotos.');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }

    if (currentPhotoType === 'front') {
      filePath = `photos/${client.idnumber}/front-${timestamp}.jpg`;
    } else if (currentPhotoType === 'back') {
      filePath = `photos/${client.idnumber}/back-${timestamp}.jpg`;
    } else if (currentPhotoType === 'pago') {
      filePath = `photos/${client.idnumber}/pago-${timestamp}.jpg`;
    }

    try {
      const storageRef = ref(storage, filePath);
      await uploadString(storageRef, photoUrl, 'data_url');
      const downloadURL = await getDownloadURL(storageRef);

      const newHeadlines = [...headlines];
      if (currentPhotoType === 'front') {
        newHeadlines[currentTitularIndex].photoFront = downloadURL;
      } else if (currentPhotoType === 'back') {
        newHeadlines[currentTitularIndex].photoBack = downloadURL;
      } else if (currentPhotoType === 'pago') {
        // Asegúrate de que photoPago sea un arreglo
        setPhotoPago((prevPhotoPago) => {
          // Agrega la nueva URL de pago al arreglo existente
          return [...prevPhotoPago, downloadURL];
        });
      }

      setHeadlines(newHeadlines);

      setToastMessage('Foto capturada y guardada con éxito.');
      setToastVariant('success');
      setShowToast(true);
    } catch (error) {
      console.error('Error al capturar o guardar la foto:', error);
      setToastMessage('Error al capturar o guardar la foto.');
      setToastVariant('danger');
      setShowToast(true);
    } finally {
      setShowCamera(false);
    }
  };


  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 mt-5">
      <Row className="w-100">
        <Col xs={12} md={12} className="d-flex justify-content-center">
          <Card>
            <Card.Header className="d-flex justify-content-center text-center">
              <div className="header-title w-100">
                <h2 className="card-title m-auto">Editar Contrato</h2>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleUpdateContract}>
                {contractData && (
                  <div>
                    <h5>Correcciones a Realizar</h5>
                    <p><strong>Correcciones:</strong> {corrections}</p>
                    <hr />

                    <h5>Detalles del Contrato</h5>
                    <p><strong>Código del Contrato:</strong> {contractCode}</p>
                    <p><strong>Cliente: </strong> <br />
                      Nombre:  {client.name} {client.lastname} <br />
                      Cedula: {client.idnumber}</p>

                    <h5>Fotos del Contrato</h5>
                    {headlines.map((titular, index) => (
                      <div key={index}>
                        <p><strong>Titular {index + 1}:</strong> {titular.name}</p>
                        {titular.photoFront && (
                          <div>
                            <img src={titular.photoFront} alt={`Foto Frontal Cedula ${index + 1}`} style={{ width: '200px', height: 'auto' }} />
                            <p>Foto Cedula Frontal</p>
                          </div>
                        )}
                        {titular.photoBack && (
                          <div>
                            <img src={titular.photoBack} alt={`Foto Trasera Cedula ${index + 1}`} style={{ width: '200px', height: 'auto' }} />
                            <p>Foto cedula Trasera</p>
                          </div>
                        )}
                      </div>
                    ))}
                    <div>
                      {photoPago ? (
                        <>
                          <img src={photoPago} alt="Foto Pago" style={{ width: '200px', height: 'auto', border: '1px solid #ddd', borderRadius: '4px' }} />
                          <p>Foto Pago Realizado</p>
                        </>
                      ) : (
                        <p>No se ha capturado ninguna foto de pago.</p>
                      )}
                    </div>
                    <hr />
                  </div>
                )}
                <h5 className="mt-4">Ciudad</h5>
                <Form.Group className="mb-3">
                  <Form.Label>Seleccionar Ciudad</Form.Label>
                  <Select
                    options={ciudadOptions}
                    value={selectCiudad}
                    onChange={(selected) => setSelectedCiudad(selected)}
                  />
                </Form.Group>
                <hr />
                {/* Sección de Titulares */}
                <h5 className="mt-4">Titulares</h5>
                {headlines.map((titular, index) => (
                  <div key={index}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={titular.name}
                        onChange={(e) => {
                          const newHeadlines = [...headlines];
                          newHeadlines[index].name = e.target.value;
                          setHeadlines(newHeadlines);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Fecha de Nacimiento</Form.Label>
                      <Form.Control
                        type="date"
                        value={titular.birthdate}
                        onChange={(e) => {
                          const newHeadlines = [...headlines];
                          newHeadlines[index].birthdate = e.target.value;
                          setHeadlines(newHeadlines);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>ID Número</Form.Label>
                      <Form.Control
                        type="text"
                        value={titular.idNumber}
                        onChange={(e) => {
                          const newHeadlines = [...headlines];
                          newHeadlines[index].idNumber = e.target.value;
                          setHeadlines(newHeadlines);
                        }}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-center mb-3 gap-3">
                      <Button
                        variant="secondary"
                        onClick={() => handleCapture('front', index)}
                        disabled={capturedStates[index]?.front}
                      >
                        Capturar Foto Frontal
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => handleCapture('back', index)}
                        disabled={capturedStates[index]?.back}
                      >
                        Capturar Foto Trasera
                      </Button>
                    </div>
                    <div>
                      <Row style={{ marginBlock: '9px', marginInline: 'auto' }}>

                        <Button
                          variant="danger"
                          onClick={() => handleRemoveTitular(index)}
                        >
                          Eliminar Titular
                        </Button>

                      </Row>
                    </div>
                  </div>


                ))}
                <div className="d-flex justify-content-center mb-3">
                  <Button
                    variant="primary"
                    onClick={handleAddTitular}
                  >
                    Agregar Titular
                  </Button>
                </div>
                <hr />
                {/* Sección de Servicios */}
                <h5 className="mt-4">Servicios</h5>
                <Form.Group className="mb-3">
                  <Form.Label>Seleccionar Servicios</Form.Label>
                  <Select
                    isMulti
                    options={serviceOptions}
                    value={selectedServices}
                    onChange={(selected) => setSelectedServices(selected)}
                  />
                </Form.Group>

                {/* Sección de Lugar de Entrenamiento */}
                <div>
                  <h5 className="mt-4">Lugar de Entrenamiento</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Seleccionar Lugar de Entrenamiento</Form.Label>
                    <Select
                      options={trainingPlaceOptions}
                      value={selectedTrainingPlace}
                      onChange={(selected) => setSelectedTrainingPlace(selected)}
                    />
                  </Form.Group>
                </div>

                {/* Sección de Tipo de Pago */}
                <div>
                  <h5 className="mt-4">Tipo de Pago</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Seleccionar Tipo de Pago</Form.Label>
                    <Select
                      options={paymentTypeOptions}
                      value={selectedPaymentType}
                      onChange={(selected) => setSelectedPaymentType(selected)}
                    />
                  </Form.Group>
                </div>

                {/* Sección de Valor Pactado Hoy */}
                <Form.Group className="mb-3">
                  <Form.Label>Valor Pactado Hoy</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Valor pactado hoy"
                    value={valorPactadoHoy}
                    onChange={(e) => setValorPactadoHoy(e.target.value)}
                  />
                </Form.Group>

                {/* Sección de Valor Inicial Hoy */}
                <Form.Group className="mb-3">
                  <Form.Label>Valor Inicial Hoy</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Valor inicial hoy"
                    value={valorInicialHoy}
                    onChange={(e) => setValorInicialHoy(e.target.value)}
                  />
                </Form.Group>

                {/* Sección de Valor Pactado Más CUI */}
                <Form.Group className="mb-3">
                  <Form.Label>Valor Pactado Más CUI</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Valor pactado más CUI"
                    value={valorPactadoMasCUI}
                    onChange={(e) => setValorPactadoMasCUI(e.target.value)}
                  />
                </Form.Group>

                {/* Sección de Estado de Venta */}
                <Form.Group className="mb-3">
                  <Form.Label>Estado de Venta</Form.Label>
                  <Select
                    options={estadoVenta}
                    value={selectedestadoVenta}
                    onChange={(selected) => setSelectedestadoVenta(selected)}
                  />
                </Form.Group>

                {/* Sección de Forma de Pago */}
                <div>
                  <h5 className="mt-4">Forma de Pago</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Seleccionar Forma de Pago</Form.Label>
                    <Select
                      options={paymentMethodOptions}
                      value={selectedPaymentMethod}
                      onChange={(selected) => setSelectedPaymentMethod(selected)}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-center mb-3 gap-3">
                    <Button
                      variant="secondary"
                      onClick={() => handleCapture('pago', 0)}
                      disabled={capturedStates[0]?.pago}
                    >
                      Capturar Foto Pago
                    </Button>
                  </div>
                </div>

                {/* Sección de Observaciones */}
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Observaciones adicionales"
                      value={observations}
                      onChange={(e) => setObservations(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>Observaciones al administrador</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Observaciones adicionales para el administrador"
                      value={observationsadmin}
                      onChange={(e) => setObservationsadmin(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-center">
                  <Button type="submit" variant="success" className="mt-4">
                    Guardar Contrato
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showCamera && (
        <CameraCapture
          show={showCamera}
          onCapture={handlePhotoCapture}
          onClose={() => setShowCamera(false)}
        />
      )}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default UpdateContract;