import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card, ToastContainer, Toast } from 'react-bootstrap';
import { db } from '../../../firebase/firebase_settings';
import { collection, query, where, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import CameraCapture from '../CameraCapture';
import Select from 'react-select';
import './Createcontract.css';
import PDFGenerator from '../pdfgenerator';


const Createcontract = () => {
    const [contractCode, setContractCode] = useState('');
    const [contractCodeaprov, setcontractCodeaprov] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [searchCriterion, setSearchCriterion] = useState('idnumber');
    const [searchValue, setSearchValue] = useState('');
    const [client, setCliente] = useState({ name: '', lastname: '', idnumber: '' });
    const [headlines, setTitulares] = useState([{ name: '', birthdate: '', idNumber: '', photoFront: '', photoBack: '' }]);
    const [showCamera, setShowCamera] = useState(false);
    const [currentPhotoType, setCurrentPhotoType] = useState('');
    const [currentTitularIndex, setCurrentTitularIndex] = useState(null);
    const [capturedStates, setCapturedStates] = useState([{ front: false, back: false }]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success'); // 'success' or 'danger'
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectCiudad, setSelectedCiudad] = useState([]);
    const [selectedTrainingPlace, setSelectedTrainingPlace] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [valorPactadoHoy, setValorPactadoHoy] = useState('');
    const [valorInicialHoy, setValorInicialHoy] = useState('');
    const [valorPactadoMasCUI, setValorPactadoMasCUI] = useState('');
    const [estadoVenta, setEstadoVenta] = useState([]);
    const [selectedestadoVenta, setSelectedestadoVenta] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);
    const [observations, setObservations] = useState('');
    const [observationsadmin, setObservationsadmin] = useState('');
    const [serviceOptions, setServiceOptions] = useState([]);
    const [trainingPlaceOptions, setTrainingPlaceOptions] = useState([]);
    const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const [photoPago, setPhotoPago] = useState('');
    const [ciudadOptions, setCiudadOptions] = useState([]);
    const navigate = useNavigate();




    useEffect(() => {
        const initializeContractCode = async () => {
            const nextCode = await getNextContractCode();
            setContractCode(nextCode);
            setCurrentDate(new Date().toISOString().split('T')[0]);
        };
        initializeContractCode();
        fetchOptions();
    },
        []);

    useEffect(() => {
        // Inicializa el estado de captura para cada titular
        setCapturedStates(headlines.map(() => ({ front: false, back: false })));
    }, [headlines]);

    const sellerId = localStorage.getItem('userId');
    // Función para generar un ID único para el usuario
    const generateRandomId = () => {
        return 'notificacion_' + Math.random().toString(36).substr(2, 9);
    };
    const notificationType = 'Notificación de pago'; // Tipo de notificación
    // Función para calcular saldo faltante y gestionar notificación
    const calcularSaldoFaltante = async () => {
        const saldo = parseFloat(valorPactadoHoy) - parseFloat(valorInicialHoy);
        const newNotificationId = generateRandomId();
        const clientName = client ? `${client.name} ${client.lastname}` : null;
        if (saldo > 0) {
            // Verificar si ya existe una notificación con el mismo id_contract y tipo 'Notificación de pago'
            const notificationsRef = collection(db, 'notifications');
            const q = query(notificationsRef,
                where('id_contract', '==', contractCode),
                where('type', '==', notificationType) // Verificamos también por tipo
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                // Si existe una notificación de este tipo, actualizamos la primera coincidencia
                const notificationDoc = querySnapshot.docs[0];
                const notificationRef = doc(db, 'notifications', notificationDoc.id);
                try {
                    await updateDoc(notificationRef, {
                        corrections: `Saldo faltante de: ${saldo.toFixed(2)}`, // Actualizar saldo
                        timestamp: new Date(), // Actualizar fecha
                    });
                    console.log("Notificación actualizada con éxito");
                } catch (e) {
                    console.error("Error al actualizar la notificación:", e);
                }
            } else {
                // Si no existe, creamos una nueva notificación
                const newNotification = {
                    id_contract: contractCode,
                    id_user: sellerId,
                    client: clientName,
                    corrections: `Saldo faltante de: ${saldo.toFixed(2)}`,
                    type: notificationType,
                    timestamp: new Date(),
                    id_notification: newNotificationId,
                };
                try {
                    await addDoc(notificationsRef, newNotification);
                    console.log("Notificación guardada con éxito:", newNotification);
                } catch (e) {
                    console.error("Error al guardar la notificación:", e);
                }
            }
        } else {
            console.log("No hay saldo pendiente");
        }
    };



    const fetchOptions = async () => {
        try {
            const optionsSnapshot = await getDocs(collection(db, 'OptionsSelect'));
            if (!optionsSnapshot.empty) {
                const optionsData = optionsSnapshot.docs[0].data();


                // Asegúrate de que cada campo sea un objeto
                const serviceOptions = formatOptions(optionsData.serviceOptions || {});
                const trainingPlaceOptions = formatOptions(optionsData.trainingPlaceOptions || {});
                const paymentTypeOptions = formatOptions(optionsData.paymentTypeOptions || {});
                const paymentMethodOptions = formatOptions(optionsData.paymentMethodOptions || {});
                const estadoVenta = formatOptions(optionsData.estadoVenta || {});
                const ciudadOptions = formatOptions(optionsData.cityOptions || {});

                setServiceOptions(serviceOptions);
                setTrainingPlaceOptions(trainingPlaceOptions);
                setPaymentTypeOptions(paymentTypeOptions);
                setPaymentMethodOptions(paymentMethodOptions);
                setEstadoVenta(estadoVenta);
                setCiudadOptions(ciudadOptions);
            } else {
                console.warn('No se encontraron documentos en la colección OptionsSelect.');
            }

        } catch (error) {
            console.error('Error al obtener las opciones de la base de datos:', error);
        }
    };

    const formatOptions = (optionsObject) => {

        return Object.keys(optionsObject).map((key) => ({
            value: key,
            label: optionsObject[key]  // Asegúrate de usar 'label' en minúsculas
        }));
    };




    const getNextContractCode = async () => {
        try {
            const q = query(collection(db, 'contracts'));
            const querySnapshot = await getDocs(q);
            let maxCode = 0;
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const code = parseInt(data.contractCode, 10);
                if (code > maxCode) {
                    maxCode = code;
                }
            });
            return (maxCode + 1).toString().padStart(6, '0');
        } catch (error) {
            console.error('Error al obtener el próximo código de contrato: ', error);
            setToastMessage('Error al obtener el proximo código de contrato');
            setToastVariant('danger');
        }
    };


    const handleSearch = async () => {
        let q;
        let queryMessage = '';

        const formattedSearchValue = searchCriterion === 'idnumber' ? parseInt(searchValue, 10) : searchValue;

        if (searchCriterion === 'idnumber') {
            q = query(collection(db, 'clients'), where('idnumber', '==', formattedSearchValue));
            queryMessage = 'buscando por número de cédula';
        } else if (searchCriterion === 'name') {
            const [name, lastName] = searchValue.split(' ');

            if (!name || !lastName) {
                setToastMessage('Por favor ingresa tanto el nombre como el apellido.');
                setToastVariant('danger');
                setShowToast(true);
                return;
            }

            q = query(collection(db, 'clients'), where('name', '==', name), where('lastname', '==', lastName));
            queryMessage = 'buscando por nombre y apellido';
        }

        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    setCliente(doc.data());
                });
                setToastMessage(`Cliente encontrado: ${queryMessage}`);
                setToastVariant('success');
            } else {
                setToastMessage('No se encontraron clientes.');
                setToastVariant('danger');
            }
            setShowToast(true);
        } catch (error) {
            console.error('Error realizando la búsqueda: ', error);
            setToastMessage('Error realizando la búsqueda.');
            setToastVariant('danger');
            setShowToast(true);
        }
    };

    const handleCriterionChange = (e) => {
        setSearchCriterion(e.target.value);
        // Borra el mensaje de error y éxito al cambiar el criterio de búsqueda
        setToastMessage('');
        setToastVariant('success');
        setSearchValue(''); // Borra el valor del campo de búsqueda
        setCliente({ name: '', lastname: '', idnumber: '' }); // Limpiar la información del cliente
    };

    const handleAddTitular = () => {
        setTitulares([...headlines, { name: '', birthdate: '', idNumber: '', photoFront: '', photoBack: '' }]);
    };

    const handleRemoveTitular = (index) => {
        setTitulares(headlines.filter((_, i) => i !== index));
    };

    const handleTitularChange = (index, event) => {
        const newTitulares = headlines.slice();
        newTitulares[index][event.target.name] = event.target.value;
        setTitulares(newTitulares);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();


        if (!client.name || !client.lastname || !client.idnumber) {
            setToastMessage('Debe buscar y seleccionar un cliente válido.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (headlines.some(titular => !titular.name || !titular.birthdate || !titular.idNumber)) {
            setToastMessage('Todos los titulares deben tener nombre, fecha de nacimiento, número de cédula y sus fotos (frontal y trasera).');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (selectedServices.length === 0) {
            setToastMessage('Debe seleccionar al menos un servicio.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (selectCiudad.length === 0) {
            setToastMessage('Debe seleccionar la ciudad.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!selectedTrainingPlace) {
            setToastMessage('Debe seleccionar un lugar para el entrenamiento.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!selectedPaymentType) {
            setToastMessage('Debe seleccionar un tipo de pago.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!valorPactadoHoy) {
            setToastMessage('El valor pactado hoy es obligatorio.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!valorInicialHoy) {
            setToastMessage('El valor inicial hoy es obligatorio.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!valorPactadoMasCUI) {
            setToastMessage('El valor pactado más CUI es obligatorio.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (!selectedestadoVenta) {
            setToastMessage('El estado de venta es obligatorio.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        if (selectedPaymentMethod.length === 0) {
            setToastMessage('Debe seleccionar al menos una forma de pago.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }

        const sellerId = localStorage.getItem('userId'); // Obtener el ID del usuario almacenado

        if (!sellerId) {
            setToastMessage('No se pudo obtener el ID del vendedor.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }

        try {
            // Guardar los datos en Firestore
            const docRef = await addDoc(collection(db, 'contracts'), {
                contractCode,
                date: currentDate,
                client,
                photoPago,
                headlines,
                ciudad: selectCiudad,
                services: selectedServices, // Guardar los servicios seleccionados
                trainingPlace: selectedTrainingPlace, // Guardar el lugar de entrenamiento seleccionado
                paymentType: selectedPaymentType, // Guardar el tipo de pago
                valorPactadoHoy,
                valorInicialHoy,
                valorPactadoMasCUI,
                estadoVenta: selectedestadoVenta,
                paymentMethod: selectedPaymentMethod, // Guardar la forma de pago
                observations, // Guardar observaciones adicionales
                observationsadmin,
                approved: false,
                id_vent: sellerId,
                corrections: '',
                contractCodeaprov: '0'
            });

            const clientName = client ? `${client.name} ${client.lastname}` : null;

            // Registrar la notificación en Firestore
            const notificationRef = collection(db, 'notifications');
            const newNotification = {
                id_contract: contractCode,
                id_user: sellerId,
                client: clientName,
                corrections: "Contrato en enviado para revisión",
                timestamp: new Date(), // Fecha y hora de la notificación
                type: "Revision"
            };
            calcularSaldoFaltante();
            setToastMessage('Contrato guardado con éxito.');
            setToastVariant('success');
            setShowToast(true);
            await addDoc(notificationRef, newNotification);

            const pdfData = {
                contractCodeaprov,
                date: currentDate,
                client,
                headlines,
                ciudad: selectCiudad,
                services: selectedServices,
                trainingPlace: selectedTrainingPlace,
                paymentType: selectedPaymentType,
                valorPactadoHoy,
                valorInicialHoy,
                valorPactadoMasCUI,
                estadoVenta: selectedestadoVenta,
                paymentMethod: selectedPaymentMethod,
                observations,
                observationsadmin
            };

            // Generar el PDF usando la función PDFGenerator
            await PDFGenerator(pdfData);

            setTimeout(() => {
                navigate('/dashboard/table/contracts-table');
            }, 1600);

        } catch (error) {
            console.error('Error al guardar el contrato: ', error);
            setToastMessage('Error al guardar el contrato.');
            setToastVariant('danger');
            setShowToast(true);
        }
    };


    const handleCapture = (type, index) => {
        setCurrentPhotoType(type);
        setCurrentTitularIndex(index);
        setShowCamera(true);
    };

    const handlePhotoCapture = async (photoUrl) => {
        const storage = getStorage();
        const updatedTitulares = headlines.slice();
        const timestamp = new Date().getTime();
        let filePath = '';

        if (currentPhotoType === 'front') {
            filePath = `photos/${client.idnumber}/front-${timestamp}.jpg`;
            const newCapturedStates = [...capturedStates];
            newCapturedStates[currentTitularIndex].front = true;
            setCapturedStates(newCapturedStates);
        } else if (currentPhotoType === 'back') {
            filePath = `photos/${client.idnumber}/back-${timestamp}.jpg`;
            const newCapturedStates = [...capturedStates];
            newCapturedStates[currentTitularIndex].back = true;
            setCapturedStates(newCapturedStates);
        } else if (currentPhotoType === 'pago') {
            filePath = `photos/${client.idnumber}/pago-${timestamp}.jpg`;
        }

        const storageRef = ref(storage, filePath);

        try {
            const snapshot = await uploadString(storageRef, photoUrl, 'data_url');
            const downloadURL = await getDownloadURL(snapshot.ref);

            if (currentPhotoType === 'front') {
                updatedTitulares[currentTitularIndex].photoFront = downloadURL;
            } else if (currentPhotoType === 'back') {
                updatedTitulares[currentTitularIndex].photoBack = downloadURL;
            } else if (currentPhotoType === 'pago') {
                // Asegúrate de que photoPago sea un arreglo separado
                if (!photoPago) {
                    setPhotoPago([]);  // Inicializa photoPago si es necesario
                }
    
                // Agrega la URL de pago al arreglo photoPago
                setPhotoPago((prevPhotoPago) => [...prevPhotoPago, downloadURL]);
            }

            setTitulares(updatedTitulares);
            setShowCamera(false);

            setToastMessage('Foto capturada y guardada correctamente.');
            setToastVariant('success');
        } catch (error) {
            console.error('Error al subir la foto a Firebase: ', error);
            setToastMessage(`Error al capturar o guardar la foto: ${error.message}`);
            setToastVariant('danger');
        }

        setShowToast(true);
    };

    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100 mt-5">
            <Row className="w-100">
                <Col xs={12} md={12} className="d-flex justify-content-center">
                    <Card>
                        <Card.Header className="d-flex justify-content-center text-center">
                            <div className="header-title w-100">
                                <h2 className="card-title m-auto">Contrato</h2>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                {/* Sección de Cliente */}
                                <h5>Datos del Cliente</h5>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="searchCriterion">Buscar Cliente Por</Form.Label>
                                    <Form.Select
                                        id="searchCriterion"
                                        value={searchCriterion}
                                        onChange={handleCriterionChange}
                                    >
                                        <option value="idnumber">Número de Cédula</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Valor de Búsqueda</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese el valor de búsqueda"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="light" onClick={handleSearch}>
                                    Buscar Cliente
                                </Button>

                                <hr />
                                {client.name && (
                                    <div>
                                        <h6>Cliente encontrado:</h6>
                                        <p>Nombre: {client.name} {client.lastname}</p>
                                        <p>Cédula: {client.idnumber}</p>
                                    </div>
                                )}
                                <h5 className="mt-4">Ciudad</h5>
                                <Form.Group className="mb-3">
                                    <Form.Label>Seleccionar Ciudad</Form.Label>
                                    <Select
                                        options={ciudadOptions}
                                        value={selectCiudad}
                                        onChange={(selected) => setSelectedCiudad(selected)}
                                    />
                                </Form.Group>
                                <hr />
                                {/* Sección de Titulares */}
                                <h5 className="mt-4">Titulares</h5>
                                {headlines.map((titular, index) => (
                                    <div key={index}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={titular.name}
                                                onChange={(e) => handleTitularChange(index, e)}
                                                placeholder="Nombre del titular"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fecha de Nacimiento</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="birthdate"
                                                value={titular.birthdate}
                                                onChange={(e) => handleTitularChange(index, e)}
                                                placeholder="Fecha de nacimiento"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Número de Cédula</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="idNumber"
                                                value={titular.idNumber}
                                                onChange={(e) => handleTitularChange(index, e)}
                                                placeholder="Número de cédula del titular"
                                                maxLength={10}
                                                pattern="\d*"
                                                required
                                            />
                                        </Form.Group>
                                        <div className="d-flex justify-content-center mb-3 gap-3">
                                            <Button
                                                variant="secondary"
                                                onClick={() => handleCapture('front', index)}
                                                disabled={capturedStates[index]?.front}
                                            >
                                                Capturar Foto Frontal
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onClick={() => handleCapture('back', index)}
                                                disabled={capturedStates[index]?.back}
                                            >
                                                Capturar Foto Trasera
                                            </Button>
                                        </div>
                                        <div>
                                            <Row style={{ marginBlock: '9px', marginInline: 'auto' }}>
                                                {index > 0 && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleRemoveTitular(index)}
                                                    >
                                                        Eliminar Titular
                                                    </Button>
                                                )}
                                            </Row>
                                        </div>

                                        <hr />
                                    </div>
                                ))}
                                <div className="d-flex justify-content-center mb-3 gap-3">
                                    <Button
                                        variant="primary"
                                        onClick={handleAddTitular}
                                    >
                                        Agregar Titular
                                    </Button>
                                </div>
                                <hr />
                                {/* Sección de Servicios */}
                                <h5 className="mt-4">Servicios</h5>
                                <Form.Group className="mb-3">
                                    <Form.Label>Seleccionar Servicios</Form.Label>
                                    <Select
                                        isMulti
                                        options={serviceOptions}
                                        value={selectedServices}
                                        onChange={(selected) => setSelectedServices(selected)}
                                    />
                                </Form.Group>

                                {/* Sección de Lugar de Entrenamiento */}
                                <div>
                                    <h5 className="mt-4">Lugar de Entrenamiento</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Seleccionar Lugar de Entrenamiento</Form.Label>
                                        <Select
                                            options={trainingPlaceOptions}
                                            value={selectedTrainingPlace}
                                            onChange={(selected) => setSelectedTrainingPlace(selected)}
                                        />
                                    </Form.Group>
                                </div>

                                {/* Sección de Tipo de Pago */}
                                <div>
                                    <h5 className="mt-4">Tipo de Pago</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Seleccionar Tipo de Pago</Form.Label>
                                        <Select
                                            options={paymentTypeOptions}
                                            value={selectedPaymentType}
                                            onChange={(selected) => setSelectedPaymentType(selected)}
                                        />
                                    </Form.Group>
                                </div>

                                {/* Sección de Valor Pactado Hoy */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Valor Pactado Hoy</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Valor pactado hoy"
                                        value={valorPactadoHoy}
                                        onChange={(e) => setValorPactadoHoy(e.target.value)}
                                    />
                                </Form.Group>

                                {/* Sección de Valor Inicial Hoy */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Valor Inicial Hoy</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Valor inicial hoy"
                                        value={valorInicialHoy}
                                        onChange={(e) => setValorInicialHoy(e.target.value)}
                                    />
                                </Form.Group>

                                {/* Sección de Valor Pactado Más CUI */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Valor Pactado Más CUI</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Valor pactado más CUI"
                                        value={valorPactadoMasCUI}
                                        onChange={(e) => setValorPactadoMasCUI(e.target.value)}
                                    />
                                </Form.Group>

                                {/* Sección de Estado de Venta */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Estado de Venta</Form.Label>
                                    <Select
                                        options={estadoVenta}
                                        value={selectedestadoVenta}
                                        onChange={(selected) => setSelectedestadoVenta(selected)}
                                    />

                                </Form.Group>

                                {/* Sección de Forma de Pago */}
                                <div>
                                    <h5 className="mt-4">Forma de Pago</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Seleccionar Forma de Pago</Form.Label>
                                        <Select
                                            options={paymentMethodOptions}
                                            value={selectedPaymentMethod}
                                            onChange={(selected) => setSelectedPaymentMethod(selected)}
                                        />
                                    </Form.Group>
                                    <div className="d-flex justify-content-center mb-3 gap-3">
                                        <Button
                                            variant="secondary"
                                            onClick={() => handleCapture('pago', 0)}
                                            disabled={capturedStates[0]?.pago}
                                        >
                                            Capturar Foto Pago
                                        </Button>
                                    </div>
                                </div>

                                {/* Sección de Observaciones */}
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Observaciones del cliente</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Observaciones adicionales para el cliente"
                                            value={observations}
                                            onChange={(e) => setObservations(e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Observaciones al administrador</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Observaciones adicionales para el administrador"
                                            value={observationsadmin}
                                            onChange={(e) => setObservationsadmin(e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button type="submit" variant="success" className="mt-4">
                                        Guardar Contrato
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {showCamera && (
                <CameraCapture
                    show={showCamera}
                    onCapture={handlePhotoCapture}
                    onClose={() => setShowCamera(false)}
                />
            )}
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant === 'success' ? 'success' : 'danger'}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Createcontract;