import React, { useState } from 'react';
import { Row, Col, Image, Form, Button, ListGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../firebase/firebase_settings'; 
// img
import facebook from '../../../assets/images/brands/fb.svg'
import google from '../../../assets/images/brands/gm.svg'
import instagram from '../../../assets/images/brands/im.svg'
import linkedin from '../../../assets/images/brands/li.svg'
import auth5 from '../../../assets/images/auth/05.png'
import Logo from '../../../assets/images/pages/logo3.png'

// Inicializa Firebase
initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

const SignUp = () => {
   let history = useNavigate();
   const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      role : ''
   });
   const [error, setError] = useState('');

   // Manejar el cambio de los inputs
   const handleChange = (e) => {
      setFormData({ ...formData, [e.target.id]: e.target.value });
   };

   // Función para registrar el usuario
   const handleSignUp = async () => {
      const { firstName, lastName, email, phone, password, confirmPassword } = formData;

      if (password !== confirmPassword) {
         setError('Las contraseñas no coinciden');
         return;
      }

      try {
         // Crear usuario en Firebase Auth
         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
         const user = userCredential.user;

         // Guardar datos adicionales en Firestore
         await setDoc(doc(db, "users", user.uid), {
            firstName,
            lastName,
            email,
            phone,
            createdAt: new Date(),
            role: 'user'

         });

         // Redirigir al usuario a otra página, por ejemplo, dashboard
         history('/dashboard');
         //ventana de alerta que se registro correctamente
         alert('Usuario registrado correctamente');
      } catch (error) {
         setError('Error al crear la cuenta: ' + error.message);
      }
   };

   return (
      <section className="login-content">
         <Row className="m-0 align-items-center bg-white vh-100">
            <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
               <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
            </div>
            <Col md="6">
               <Row className="justify-content-center">
                  <Col md="15">
                     <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                        <Card.Body>
                           <Link to="/" className="navbar-brand d-flex align-items-center mb-3">
                              <img src={Logo} alt="logo" className="img-fluid" style={{ height: "4vh" }} />
                              <h4 className="logo-title ms-3">Stroit Corp</h4>
                           </Link>
                           <h2 className="mb-2 text-center">Registrate</h2>
                           <p className="text-center">Cree su cuenta en Stroit Corp</p>
                           {error && <p className="text-danger text-center">{error}</p>}
                           <Form>
                              <Row>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="firstName">Nombres</Form.Label>
                                       <Form.Control type="text" id="firstName" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="lastName">Apellidos</Form.Label>
                                       <Form.Control type="text" id="lastName" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="email">Correo Electrónico</Form.Label>
                                       <Form.Control type="email" id="email" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="phone">No. Celular</Form.Label>
                                       <Form.Control type="text" id="phone" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="password">Contraseña</Form.Label>
                                       <Form.Control type="password" id="password" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="confirmPassword">Repita la contraseña</Form.Label>
                                       <Form.Control type="password" id="confirmPassword" placeholder=" " onChange={handleChange} />
                                    </Form.Group>
                                 </Col>
                              </Row>
                              <div className="d-flex justify-content-center">
                                 <Button onClick={handleSignUp} type="button" variant="primary">Registrarse</Button>
                              </div>
                              <p className="text-center my-3">O registrate con otras cuentas?</p>
                                 <div className="d-flex justify-content-center">
                                    <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                       <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                          <Link to="#"><Image src={facebook} alt="fb" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                          <Link to="#"><Image src={google} alt="gm" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                          <Link to="#"><Image src={instagram} alt="im" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                          <Link to="#"><Image src={linkedin} alt="li" /></Link>
                                       </ListGroup.Item>
                                    </ListGroup>
                                 </div>
                                 <p className="mt-3 text-center">
                                 Ya tienes una cuenta? <Link to="/auth/sign-in" className="text-underline">Inicie Sesión</Link>
                                 </p>
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Col>
         </Row>
      </section>
   );
};

export default SignUp;